import "./App.scss"
import "./globals.scss"

import { createBrowserHistory } from "history"
import React from "react"
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from "react-router-dom"

import ReservationRedirection from "./components/atoms/ReservationRedirection"
import LoginPage from "./components/pages/LoginPage"
import Page404 from "./components/pages/Page404"
import RegisterPage from "./components/pages/RegisterPage"
import TripPage from "./components/pages/TripPage"
import TripPagePNR from "./components/pages/TripPagePNR"
import TripsPage from "./components/pages/TripsPage"
import AuthenticatedRoute from "./components/routers/AuthenticatedRoute"
import GuestRoute from "./components/routers/GuestRoute"

export const history = createBrowserHistory()

const App = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/">
          <Route
            path=""
            element={
              <AuthenticatedRoute>
                <TripsPage />
              </AuthenticatedRoute>
            }
          />

          <Route path=":tripHash" element={<ReservationRedirection />} />

          <Route path="/reservation/:tripHash" element={<TripPage />} />

          <Route path="/reservation/" element={<Page404 />} />

          <Route path="/trip" element={<Page404 />} />

          <Route
            path="/trip/:pnr"
            element={
              <AuthenticatedRoute>
                <TripPagePNR />
              </AuthenticatedRoute>
            }
          />
        </Route>

        <Route
          path="/login"
          element={
            <GuestRoute>
              <LoginPage />
            </GuestRoute>
          }
        />

        <Route path="/register">
          <Route
            path=""
            element={
              <GuestRoute>
                <RegisterPage />
              </GuestRoute>
            }
          />

          <Route
            path=":tripHash"
            element={
              <GuestRoute>
                <RegisterPage />
              </GuestRoute>
            }
          />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </HistoryRouter>
  )
}

export default App
