import "./TripsPage.scss"

import React, { useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/appSettings"
import { getPastTrips, getUpcomingTrips } from "../../../redux/trip/tripActions"
import apiUtils from "../../../utils/api/apiUtils"
import Button from "../../atoms/Button"
import Loading from "../../atoms/Loading"
import RegularLayout from "../../layouts/RegularLayout"
import CustomerTripRow from "../../molecules/CustomerTripRow"

const TripsPage = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(0)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    setOffset(0)
    if (activeTab === 0) {
      dispatch(getUpcomingTrips(0))
    } else {
      dispatch(getPastTrips(0))
    }
  }, [activeTab])

  const [trips, callsInProgress] = useSelector(({ trip, api }) => [
    trip.allTrips,
    api.callsInProgress,
  ])

  const tripBusy = useMemo(() => {
    return apiUtils.areCallsInProgress(
      [API.GET_UPCOMING_TRIPS, API.GET_PAST_TRIPS],
      callsInProgress,
    )
  }, [callsInProgress])

  const initialLoading = useMemo(() => {
    return (
      apiUtils.areCallsInProgress([API.GET_UPCOMING_TRIPS, API.GET_PAST_TRIPS], callsInProgress) &&
      offset === 0
    )
  }, [callsInProgress, offset])

  const loadMore = () => {
    if (!tripBusy) {
      const currentOffset = offset + PAGE_LIMIT

      if (activeTab === 0) {
        dispatch(getUpcomingTrips(currentOffset))
      } else {
        dispatch(getPastTrips(currentOffset))
      }

      setOffset(currentOffset)
    }
  }

  const getPageContent = () =>
    trips && trips.results.length > 0 ? (
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={!!trips?.next}
        pageStart={0}
        loader={<Loading className="scroll" key={trips?.next} />}
        threshold={100}
        useWindow={false}
      >
        <div className="scroll-content-wrapper">
          {trips && trips.results.map(trip => <CustomerTripRow trip={trip} key={trip.rloc} />)}
        </div>
      </InfiniteScroll>
    ) : (
      <div className="empty-state-image-wrapper">
        <img
          className="empty-state-image"
          src="./media/customer-empty-state.svg"
          alt="Empty state"
        />
      </div>
    )

  return (
    <RegularLayout withTopMenu withLogout fetchProfile>
      <div className="trips-page-container">
        <div className="fixed-content">
          <div className="scheduled-rides-text">Scheduled rides</div>
          <div className="button-wrapper">
            <Button
              text="Upcoming"
              buttonStyle={activeTab === 0 ? "primary" : "white"}
              tripsSelection
              onClick={() => setActiveTab(0)}
            />
            <Button
              text="Past trips"
              buttonStyle={activeTab === 1 ? "primary" : "white"}
              tripsSelection
              onClick={() => setActiveTab(1)}
            />
          </div>

          <div className="breaking-line" />
        </div>

        {initialLoading ? <Loading className="page-content-loading" /> : getPageContent()}
      </div>
    </RegularLayout>
  )
}

TripsPage.propTypes = {}

TripsPage.defaultProps = {}

export default TripsPage
