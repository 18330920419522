import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  coordinates: {},
  tripDetails: {},
  depart_editable_leg_id: undefined,
  return_editable_leg_id: undefined,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_UPCOMING_TRIPS_SUCCESS:
    case ACTIONS.GET_PAST_TRIPS_SUCCESS:
      if (action.timestamp >= state.requestTimestamp) {
        if (action.offset) {
          return {
            ...state,
            allTrips: {
              ...state.allTrips,
              next: action.trips.next,
              results: [...state.allTrips.results, ...action.trips.results],
            },
          }
        }
        return {
          ...state,
          allTrips: action.trips,
        }
      }
      return { ...state }

    case ACTIONS.START_TRIP_SUCCESS:
    case ACTIONS.COMPLETE_TRIP_SUCCESS:
    case ACTIONS.GET_TRIP_DETAILS_SUCCESS: {
      return {
        ...state,
        tripDetails: { ...state.tripDetails, [action.tripID]: action.tripDetails },
      }
    }

    case ACTIONS.RESET_TRIP_STATE_SUCCESS: {
      return {
        ...initialState,
      }
    }

    case ACTIONS.GET_TRIP_BY_PNR_SUCCESS:
    case ACTIONS.GET_HASH_TRIP_SUCCESS: {
      return {
        ...initialState,
        selectedTrip: action.selectedTrip,
      }
    }

    case ACTIONS.SAVE_PASSENGER_NOTE_SUCCESS: {
      return {
        ...state,
        selectedTrip: {
          ...state.selectedTrip,
          segments: state.selectedTrip.segments.map((seg, index) => {
            if (index === action.segment) {
              return {
                ...state.selectedTrip.segments[action.segment],
                legs: state.selectedTrip.segments[action.segment].legs.map(leg => {
                  if (leg.id === action.updatedLeg.id) {
                    const newLeg = JSON.parse(JSON.stringify(leg))
                    newLeg.notes = action.updatedLeg.notes
                    return newLeg
                  }

                  return leg
                }),
              }
            }

            return seg
          }),
        },
      }
    }

    case ACTIONS.UPDATE_PASSENGER_NOTE_SUCCESS: {
      return {
        ...state,
        selectedTrip: {
          ...state.selectedTrip,
          segments: state.selectedTrip.segments.map((seg, index) => {
            if (index === action.segment) {
              return {
                ...state.selectedTrip.segments[action.segment],
                legs: state.selectedTrip.segments[action.segment].legs.map(leg => {
                  if (leg.id === action.legID) {
                    return {
                      ...leg,
                      notes: leg.notes.map(note => {
                        if (note.id === action.note.id) {
                          return action.note
                        }

                        return note
                      }),
                    }
                  }

                  return leg
                }),
              }
            }

            return seg
          }),
        },
      }
    }

    case ACTIONS.DELETE_PASSENGER_NOTE_SUCCESS: {
      return {
        ...state,
        selectedTrip: {
          ...state.selectedTrip,
          segments: state.selectedTrip.segments.map((seg, index) => {
            if (index === action.segment) {
              return {
                ...state.selectedTrip.segments[action.segment],
                legs: state.selectedTrip.segments[action.segment].legs.map(leg => {
                  if (leg.id === action.legID) {
                    return {
                      ...leg,
                      notes: leg.notes.filter(note => note.id !== action.note.id),
                    }
                  }

                  return leg
                }),
              }
            }

            return seg
          }),
        },
      }
    }

    case ACTIONS.UPDATE_PICKUP_DETAILS_SUCCESS: {
      return {
        ...state,
        selectedTrip: {
          ...state.selectedTrip,
          segments: state.selectedTrip.segments.map((seg, index) => {
            if (index === action.segment) {
              return {
                ...state.selectedTrip.segments[action.segment],
                legs: state.selectedTrip.segments[action.segment].legs.map(leg => {
                  if (leg.id === action.updatedLeg.id) {
                    return action.updatedLeg
                  }

                  return leg
                }),
              }
            }

            return seg
          }),
        },
      }
    }

    case ACTIONS.SET_EDIT_LEG_ID_SUCCESS: {
      return {
        ...state,
        [`${action.direction}_editable_leg_id`]: action.legID,
      }
    }

    case ACTIONS.UPDATE_TIME_OFFSET_SUCCESS:
    case ACTIONS.SWITCH_CHECKED_BAGGAGE_SUCCESS: {
      return {
        ...state,
        selectedTrip: {
          ...state.selectedTrip,
          segments: state.selectedTrip.segments.map(seg => {
            if (seg.id === action.updatedSegment.id) {
              return action.updatedSegment
            }

            return seg
          }),
        },
      }
    }

    case ACTIONS.CLEAR_SELECTED_TRIP_SUCCESS: {
      return {
        ...state,
        selectedTrip: undefined,
      }
    }

    case ACTIONS.SET_TIMESTAMP:
      return {
        ...state,
        requestTimestamp: action.timestamp,
      }

    default:
      return state
  }
}
