import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  coordinates: {},
  tripDetails: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        addressList: action.addressList,
      }

    case ACTIONS.CLEAR_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        addressList: [],
      }

    case ACTIONS.VALIDATE_ADDRESS_SUCCESS: {
      return {
        ...state,
        validatedAllowedRadius: action.allowed_radius,
      }
    }

    default:
      return state
  }
}
