export default {
  // user actions
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  REFRESH_ACCESS_TOKEN: "REFRESH_ACCESS_TOKEN",
  DECODE_JWT: "DECODE_JWT",
  REGISTER_USER: "REGISTER_USER",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_ACCOUNT_INFO: "GET_ACCOUNT_INFO",

  // trip actions
  GET_UPCOMING_TRIPS: "GET_UPCOMING_TRIPS",
  GET_PAST_TRIPS: "GET_PAST_TRIPS",
  GET_TRIP_DETAILS: "GET_TRIP_DETAILS",
  GET_NEXT_TRIPS_PAGE: "GET_NEXT_TRIPS_PAGE",
  START_TRIP: "START_TRIP",
  COMPLETE_TRIP: "COMPLETE_TRIP",
  GET_HASH_TRIP: "GET_HASH_TRIP",
  UPDATE_PICKUP_DETAILS: "UPDATE_PICKUP_DETAILS",
  SWITCH_CHECKED_BAGGAGE: "SWITCH_CHECKED_BAGGAGE",
  GET_TRIP_BY_PNR: "GET_TRIP_BY_PNR",
  UPDATE_TIME_OFFSET: "UPDATE_TIME_OFFSET",
  SAVE_PASSENGER_NOTE: "SAVE_PASSENGER_NOTE",
  UPDATE_PASSENGER_NOTE: "UPDATE_PASSENGER_NOTE",
  DELETE_PASSENGER_NOTE: "DELETE_PASSENGER_NOTE",
  SEND_CONFIRMATION_EMAIL: "SEND_CONFIRMATION_EMAIL",

  // address actions
  GET_ADDRESS: "GET_ADDRESS",
  VALIDATE_ADDRESS: "VALIDATE_ADDRESS",
}
