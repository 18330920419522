import "./AddressSearchInfo.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { INVALID_ADDRESS, MISSING_INFO, VALID_ADDRESS } from "../../../constants/addressStatus"
import SvgIcon from "../SvgIcon"

const AddressSearchInfo = ({ status, firstLeg }) => {
  const containerStyle = classNames("address-search-info-container", { firstLeg })

  const getContent = () => {
    let title
    let icon
    let details
    switch (status) {
      case MISSING_INFO:
        title = "Missing info"
        icon = "warning-sign"
        details = "Please enter your home address."
        break

      case INVALID_ADDRESS:
        title = "The address is outside of the service radius"
        details = "If you need any assistance please feel free to contact us."
        icon = "invalid-address"
        break

      case VALID_ADDRESS:
        title = "Thank you for updating your address"
        details = "Please feel free to contact us if you need any further information."
        icon = "valid-address"
        break

      default:
        break
    }

    return { title, icon, details }
  }

  return (
    <div className={containerStyle}>
      <div className="title-button-wrapper">
        <div className="title-icon-wrapper">
          <div className="icon-wrapper">
            <SvgIcon icon={getContent().icon} width={17} height={17} />
          </div>

          <p className="title">{getContent().title}</p>
        </div>
      </div>

      <p className="details">{getContent().details}</p>
    </div>
  )
}

AddressSearchInfo.propTypes = {
  status: PropTypes.number,
  firstLeg: PropTypes.bool,
}

AddressSearchInfo.defaultProps = {
  status: 1,
  firstLeg: false,
}

export default AddressSearchInfo
