import "./ExistingTripNote.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { deletePassengerNote, updatePassengerNote } from "../../../redux/trip/tripActions"
import SvgIcon from "../SvgIcon"

const ExistingTripNote = ({
  note,
  editing,
  setEditing,
  tripHash,
  isDepart,
  legID,
  resetAddNote,
}) => {
  const dispatch = useDispatch()
  const [newNoteValue, setNewNoteValue] = useState(note.description)
  const [shouldUpdate, setShouldUpdate] = useState(true)

  const handleEditNote = useCallback(() => {
    dispatch(
      updatePassengerNote(
        note.id,
        tripHash,
        newNoteValue,
        () => {
          setShouldUpdate(false)
          setEditing(undefined)
        },
        isDepart ? SEGMENT_DEPART : SEGMENT_RETURN,
        legID,
      ),
    )
  }, [tripHash, newNoteValue, note.id, legID])

  const handleDeleteNote = useCallback(() => {
    dispatch(
      deletePassengerNote(
        note.id,
        tripHash,
        () => {
          setEditing(undefined)
        },
        isDepart ? SEGMENT_DEPART : SEGMENT_RETURN,
        legID,
      ),
    )
  }, [tripHash, note.id, legID])

  useEffect(() => {
    if (shouldUpdate && editing !== note.id) {
      setNewNoteValue(note.description)
    }
    setShouldUpdate(true)
  }, [editing])

  useEffect(() => {
    setNewNoteValue(note.description)
  }, [note.description])

  return (
    <div className="existing-trip-note-container">
      <div className="existing-trip-note-wrapper">
        <div className="date-and-time">
          {moment.utc(note.created).local().format("MMM DD, hh:mm a")}
        </div>
        <div className="icon-wrapper">
          <div role="button" onClick={handleDeleteNote} className="clickable-icon">
            <SvgIcon icon="trash-bin" width={30} height={30} />
          </div>
          <div
            role="button"
            onClick={() => {
              resetAddNote()
              setEditing(note.id)
            }}
            className="clickable-icon"
          >
            <SvgIcon icon="edit-icon" width={30} height={30} />
          </div>
        </div>
      </div>
      <textarea
        disabled={editing !== note.id}
        className="add-new-body_content"
        onChange={e => {
          setNewNoteValue(e.target.value)
        }}
        value={newNoteValue}
        rows="2"
      />

      {editing === note.id && (
        <div className="add-new-body_actions">
          <span
            role="button"
            className="trip-note-button"
            onClick={() => {
              setEditing(undefined)
              setNewNoteValue(note.description)
            }}
          >
            Cancel
          </span>
          <span role="button" className="trip-note-button save" onClick={handleEditNote}>
            Save
          </span>
        </div>
      )}
    </div>
  )
}

ExistingTripNote.propTypes = {
  note: PropTypes.instanceOf(Object),
  editing: PropTypes.number,
  setEditing: PropTypes.func,
  tripHash: PropTypes.string,
  isDepart: PropTypes.bool,
  legID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  resetAddNote: PropTypes.func,
}

ExistingTripNote.defaultProps = {
  note: {},
  editing: undefined,
  setEditing: () => {},
  tripHash: "",
  isDepart: false,
  legID: "",
  resetAddNote: () => {},
}

export default ExistingTripNote
