import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router"

import { clearSelectedTrip, getTripByPNR } from "../../../redux/trip/tripActions"
import RegularLayout from "../../layouts/RegularLayout"
import TripDetails from "../../organisms/TripDetails"

const TripPagePNR = () => {
  const dispatch = useDispatch()
  const { pnr } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (pnr) {
      dispatch(getTripByPNR(pnr, navigate))
    }

    return () => dispatch(clearSelectedTrip())
  }, [pnr])

  return (
    <RegularLayout withTopMenu withLogout fetchProfile>
      <TripDetails />
    </RegularLayout>
  )
}

TripPagePNR.propTypes = {}

TripPagePNR.defaultProps = {}

export default TripPagePNR
