import "./SearchDropdown.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { useOnClickOutside } from "../../../utils/hooks"
import InputField from "../InputField"
import Loading from "../Loading"

const SearchDropdown = ({
  onItemClick,
  value,
  setValue,
  items,
  showItemField,
  showSecondItemField,
  placeholder,
  className,
  onFocus,
  textInputStyle,
  field,
  noTopMargin,
  loading,
  isModal,
}) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const ref = useRef()

  useOnClickOutside(ref, () => setIsOpenState(false))

  const dropdownClass = classNames("search-dropdown-container", className, { noTopMargin })

  const handleOnFocus = () => {
    if (!isOpenState) {
      onFocus()
      setIsOpenState(true)
    }
  }

  const getFormattedValue = valueItem => {
    if (field === "driver") {
      return `${valueItem[showItemField]} ${valueItem[showSecondItemField]}`
    }

    return showSecondItemField
      ? `${valueItem[showSecondItemField]} (${valueItem[showItemField]})`
      : valueItem[showItemField]
  }

  return (
    <div className={dropdownClass} ref={ref}>
      <InputField
        fullWidth
        value={value}
        onChange={val => {
          setValue(val)
        }}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        inputStyle={textInputStyle}
        isModal={isModal}
      />
      {loading && (
        <div className="loading-wrapper">
          <Loading buttonLoading />
        </div>
      )}

      {isOpenState && items && items.length > 0 && (
        <div className="activeList">
          {items.map(item => {
            return (
              <div
                key={item.description}
                onClick={() => {
                  onItemClick(item)
                  setIsOpenState(false)
                }}
                role="button"
                className="item"
                tabIndex={0}
              >
                {getFormattedValue(item)}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

SearchDropdown.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  showItemField: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSecondItemField: PropTypes.string,
  onFocus: PropTypes.func,
  textInputStyle: PropTypes.string,
  field: PropTypes.string,
  noTopMargin: PropTypes.bool,
  loading: PropTypes.bool,
  isModal: PropTypes.bool,
}

SearchDropdown.defaultProps = {
  showItemField: "",
  placeholder: "",
  className: "",
  showSecondItemField: "",
  onFocus: () => {},
  textInputStyle: "",
  field: "",
  noTopMargin: false,
  loading: false,
  isModal: false,
}

export default SearchDropdown
