import "./CustomerTripRow.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { useNavigate } from "react-router"

import { BLACK_CAR_TYPE } from "../../../constants/transportationTypes"
import { CANCELLED_STATUS } from "../../../constants/tripStatus"
import AddressWarning from "../../atoms/AddressWarning"
import LegConnectionPreview from "../../atoms/LegConnectionPreview"

const CustomerTripRow = ({ trip }) => {
  const customerTripRowClass = classNames("customer-trip-row-container", {})
  const navigate = useNavigate()

  const pnrTitleClass = classNames("pnr-title", {
    past: trip?.is_past_trip,
    cancelled: trip?.status === CANCELLED_STATUS,
  })

  const checkAddress = () => {
    if (trip.is_past_trip) {
      return null
    }

    const allLegs = [...trip.segments[0].legs]

    if (trip.segments[1]) {
      allLegs.push(...trip.segments[1].legs)
    }

    const allEditableLegs = allLegs.filter(
      leg =>
        leg.is_editable &&
        leg.service_type === BLACK_CAR_TYPE &&
        (leg.depart.virtual || leg.arrive.virtual),
    )

    if (allEditableLegs.length === 0) {
      return null
    }

    const checkIfMissingAddress = leg => {
      if (leg.depart.virtual) {
        return (
          leg.pick_up_details.pick_up_address.first_address_line === "" ||
          leg.pick_up_details.pick_up_address.first_address_line === "/"
        )
      }

      return (
        leg.pick_up_details.drop_off_address.first_address_line === "" ||
        leg.pick_up_details.drop_off_address.first_address_line === "/"
      )
    }

    const missingAddressLeg = allEditableLegs.find(leg => checkIfMissingAddress(leg))

    if (missingAddressLeg) {
      return <AddressWarning icon="warning-sign" text="Missing address" />
    }

    const outsideOfServiceRadiusLeg = allEditableLegs.find(
      leg => leg.pick_up_details.allowed_radius > 0,
    )

    if (outsideOfServiceRadiusLeg) {
      return <AddressWarning icon="invalid-address" text="Contact us" />
    }

    return null
  }

  const ref = useRef()

  return (
    <div
      className={customerTripRowClass}
      role="button"
      onClick={() => {
        navigate(`/trip/${trip.rloc}`)
      }}
      ref={ref}
      key={trip.id}
    >
      <div className="pnr-wrapper">
        <h2 className={pnrTitleClass}>{trip.rloc}</h2>
        {checkAddress()}
      </div>

      <LegConnectionPreview tripDetails={trip.segments[0].legs} isDepart />
      {trip.segments.length > 1 && <LegConnectionPreview tripDetails={trip.segments[1].legs} />}
    </div>
  )
}

CustomerTripRow.propTypes = {
  trip: PropTypes.instanceOf(Object),
}

CustomerTripRow.defaultProps = {
  trip: {},
}

export default CustomerTripRow
