import "./RegularLayout.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { decodeJWT, getUserProfile } from "../../../redux/user/userActions"
import Logout from "../../atoms/Logout"
import TopBar from "../../atoms/TopBar"

const RegularLayout = ({
  children,
  withTopMenu,
  backgroundColor,
  withLogout,
  fetchProfile,
  isHashTrip,
}) => {
  const dispatch = useDispatch()

  const regularLayoutClass = classNames("regular-layout-container", {
    withTopMenu,
    withLogout,
  })

  useEffect(() => {
    if (fetchProfile) {
      dispatch(getUserProfile())
      dispatch(decodeJWT())
    }
  }, [fetchProfile])

  const [userProfile] = useSelector(({ user }) => [user.userProfile])

  return (
    <div className={regularLayoutClass} style={{ backgroundColor }}>
      {withTopMenu && <TopBar userProfile={userProfile} />}
      <div className="regular-layout-content">{children}</div>
      {withLogout && <Logout isHashTrip={isHashTrip} />}
    </div>
  )
}

RegularLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]).isRequired,
  withTopMenu: PropTypes.bool,
  backgroundColor: PropTypes.string,
  withLogout: PropTypes.bool,
  fetchProfile: PropTypes.bool,
  isHashTrip: PropTypes.bool,
}

RegularLayout.defaultProps = {
  withTopMenu: false,
  backgroundColor: "",
  withLogout: false,
  fetchProfile: false,
  isHashTrip: false,
}

export default RegularLayout
