import "./TripPage.scss"

import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"

import { clearSelectedTrip, getHashTrip } from "../../../redux/trip/tripActions"
import RegularLayout from "../../layouts/RegularLayout"
import TripDetails from "../../organisms/TripDetails"

const TripPage = () => {
  const dispatch = useDispatch()
  const { tripHash } = useParams()

  const [userData] = useSelector(({ user }) => [user])

  const { userProfile, accountInfo } = userData

  const loggedIn = useMemo(() => {
    const accessToken = localStorage.getItem("accessToken")
    return !!accessToken
  }, [userProfile])

  useEffect(() => {
    if (tripHash) {
      dispatch(getHashTrip(tripHash))
    }

    return () => dispatch(clearSelectedTrip())
  }, [tripHash])

  return (
    <RegularLayout withTopMenu withLogout={loggedIn} fetchProfile={loggedIn} isHashTrip>
      <TripDetails tripHash={tripHash} accountInfo={accountInfo} loggedIn={loggedIn} />
    </RegularLayout>
  )
}

TripPage.propTypes = {}

TripPage.defaultProps = {}

export default TripPage
