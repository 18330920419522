import "./RegisterPage.scss"

import React, { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"

import API from "../../../constants/API"
import { resetTripState } from "../../../redux/trip/tripActions"
import { getAccountInfo, registerUser, registerUserWithHash } from "../../../redux/user/userActions"
import apiUtils from "../../../utils/api/apiUtils"
import { validateHashSignup, validateSignUp } from "../../../utils/validation"
import Button from "../../atoms/Button"
import InputField from "../../atoms/InputField"
import SocialNetworks from "../../atoms/SocialNetworks"
import SvgIcon from "../../atoms/SvgIcon"

const RegisterPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tripHash } = useParams()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [retypePasswordVisible, setRetypePasswordVisible] = useState(false)
  const [accountDetails, setAccountDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    retypePassword: "",
  })
  const [formErrors, setFormErrors] = useState({})

  const handleFieldChange = (field, value) => {
    setAccountDetails(prevState => ({ ...prevState, [field]: value }))
  }

  useEffect(() => {
    if (tripHash) {
      dispatch(getAccountInfo(tripHash))
    }
  }, [tripHash])

  const handleRegisterClick = () => {
    if (tripHash) {
      const errors = validateHashSignup(accountDetails)
      if (errors) {
        setFormErrors(errors)
      } else {
        setFormErrors({})
        dispatch(
          registerUserWithHash(
            {
              phone_number: accountDetails.phone_number,
              new_password: accountDetails.password,
              repeated_password: accountDetails.retypePassword,
            },
            navigate,
            tripHash,
          ),
        )
      }
    } else {
      const errors = validateSignUp(accountDetails)
      if (errors) {
        setFormErrors(errors)
      } else {
        setFormErrors({})
        dispatch(registerUser(accountDetails, navigate))
      }
    }
  }

  const passwordRef = useRef()

  const [callsInProgress, accountInfo] = useSelector(({ api, user }) => [
    api.callsInProgress,
    user.accountInfo,
  ])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.REGISTER_USER], callsInProgress)
  }, [callsInProgress])

  const formatPhoneInput = value => {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, "")
    const cvLength = currentValue.length

    if (cvLength < 4) return currentValue
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
  }

  useEffect(() => {
    dispatch(resetTripState())
  }, [])

  return (
    <div className="register-container">
      <img
        className="background-vector-1"
        width={234}
        height={140}
        src="/media/background-vector-1.png"
        alt=""
      />

      <img
        className="background-vector-2"
        width={195}
        height={81}
        src="/media/background-vector-2.png"
        alt=""
      />

      <div className="register-content-container">
        <div className="logo-container">
          <img className="logo" width={147} height={24} src="/media/landline.png" alt="" />
        </div>

        <InputField
          label="First name"
          placeholder="Enter first name"
          inputStyle="primary"
          margin="0px 0px 0px 0px"
          onChange={val => handleFieldChange("first_name", val)}
          value={tripHash ? accountInfo?.first_name : accountDetails.first_name}
          error={formErrors.first_name}
          disabled={!!tripHash}
        />
        <InputField
          label="Last name"
          placeholder="Enter last name"
          inputStyle="primary"
          margin="10px 0px 0px 0px"
          onChange={val => handleFieldChange("last_name", val)}
          value={tripHash ? accountInfo?.last_name : accountDetails.last_name}
          error={formErrors.last_name}
          disabled={!!tripHash}
        />
        <InputField
          label="Phone number"
          placeholder="Enter phone number"
          inputStyle="primary"
          margin="10px 0px 0px 0px"
          onChange={val => {
            handleFieldChange("phone_number", formatPhoneInput(val))
          }}
          value={accountDetails.phone_number}
          error={formErrors.phone_number}
          type="tel"
        />
        <InputField
          type="email"
          label="Email"
          placeholder="Enter email"
          inputStyle="primary"
          margin="10px 0px 0px 0px"
          onChange={val => handleFieldChange("email", val)}
          value={tripHash ? accountInfo?.email : accountDetails.email}
          error={formErrors.email}
          name="email"
          autoComplete="email"
          disabled={!!tripHash}
        />
        <InputField
          type={passwordVisible ? "text" : "password"}
          label="Create password"
          placeholder="Enter password"
          inputStyle="primary"
          margin="10px 0px 0px 0px"
          onChange={val => handleFieldChange("password", val)}
          value={accountDetails.password}
          error={formErrors.password}
          icon={<SvgIcon icon={passwordVisible ? "eye-show" : "eye-hide"} />}
          onIconClick={() => setPasswordVisible(!passwordVisible)}
          inputRef={passwordRef}
          name="new-password"
          autoComplete="new-password"
        />
        <InputField
          type={retypePasswordVisible ? "text" : "password"}
          label="Retype password"
          placeholder="Enter password"
          inputStyle="primary"
          margin="10px 0px 0px 0px"
          onChange={val => handleFieldChange("retypePassword", val)}
          value={accountDetails.retypePassword}
          error={formErrors.retypePassword}
          icon={<SvgIcon icon={retypePasswordVisible ? "eye-show" : "eye-hide"} />}
          onIconClick={() => setRetypePasswordVisible(!retypePasswordVisible)}
          name="new-password"
          autoComplete="new-password"
        />
        <Button
          text="Create Account"
          onClick={handleRegisterClick}
          buttonStyle="secondary"
          margin="26px 0px 10px 0px"
          fullWidth
          loading={loading}
        />

        <div className="create-account-wrapper">
          <p>Already have an account?</p>
          <div role="button" className="create-account-link" onClick={() => navigate("/login")}>
            Log In
          </div>
        </div>
        <SocialNetworks marginBottom={10} />
      </div>
    </div>
  )
}

RegisterPage.propTypes = {}

RegisterPage.defaultProps = {}

export default RegisterPage
