import "./TimePicker.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { useOnClickOutside } from "../../../utils/hooks"
import SvgIcon from "../SvgIcon"
import TimePickerDropdown from "../TimePickerDropdown"

const TimePicker = ({
  date,
  time,
  isEditable,
  isFirst,
  offsetDetails,
  tripHash,
  legID,
  setEditing,
  isDepart,
  segmentID,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()

  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <div className="time-picker-container" ref={ref}>
      {isFirst && isEditable && (
        <div className="time-edit-container isFirst">
          <div
            className="time-edit-wrapper isFirst"
            role="button"
            onClick={() => {
              setEditing(undefined)
              setIsOpen(!isOpen)
            }}
          >
            <SvgIcon icon={isOpen ? "close" : "edit-icon"} width={30} height={30} />
          </div>
        </div>
      )}

      <div className="time-wrapper">{moment(`${date}T${time}`).format("hh:mm A")}</div>

      {!isFirst && isEditable && (
        <div className="time-edit-container">
          <div
            className="time-edit-wrapper"
            role="button"
            onClick={() => {
              setEditing(undefined)
              setIsOpen(!isOpen)
            }}
          >
            <SvgIcon icon={isOpen ? "close" : "edit-icon"} width={30} height={30} />
          </div>
        </div>
      )}

      {isOpen && (
        <TimePickerDropdown
          isFirst={isFirst}
          offsetDetails={offsetDetails}
          departureTime={`${date}T${time}`}
          tripHash={tripHash}
          legID={legID}
          setIsOpen={setIsOpen}
          isDepart={isDepart}
          segmentID={segmentID}
        />
      )}
    </div>
  )
}

TimePicker.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,
  isEditable: PropTypes.bool,
  isFirst: PropTypes.bool,
  offsetDetails: PropTypes.instanceOf(Object),
  tripHash: PropTypes.string,
  legID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setEditing: PropTypes.func,
  isDepart: PropTypes.bool,
  segmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TimePicker.defaultProps = {
  date: "",
  time: "",
  isEditable: false,
  isFirst: false,
  offsetDetails: {},
  tripHash: "",
  legID: "",
  setEditing: () => {},
  isDepart: false,
  segmentID: "",
}

export default TimePicker
