import ACTIONS from "../../constants/ACTIONS"

const initialState = {}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.DECODE_JWT_SUCCESS:
      return {
        ...state,
        jwtData: action.jwtDecoded,
      }

    case ACTIONS.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.response,
      }

    case ACTIONS.LOGOUT_USER:
      return initialState

    case ACTIONS.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfo: action.response,
      }

    default:
      return state
  }
}
