import "./PickupTimeContent.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useState } from "react"

import TimePickerDropdown from "../TimePickerDropdown"

const PickupTimeContent = ({ tripHash, editableLeg, segmentID }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <div className="pickup-time-content-container">
      <p className="additional-text">
        Here is our suggested pick up time, but you are free to change it.
      </p>
      <p className="label">Pick up time</p>

      <div className="time-preview" role="button" onClick={() => setDropdownOpen(!dropdownOpen)}>
        {moment(`${editableLeg.departure_date}T${editableLeg.departure_time}`).format("hh:mm a")}
        {dropdownOpen && (
          <TimePickerDropdown
            isFirst
            isDepart
            tripHash={tripHash}
            offsetDetails={editableLeg.pick_up_details.offset_details}
            isModal
            setIsOpen={setDropdownOpen}
            departureTime={`${editableLeg.departure_date}T${editableLeg.departure_time}`}
            legID={editableLeg.id}
            segmentID={segmentID}
          />
        )}
      </div>

      <div className="arrival-container">
        <div className="arrival-dot" />
        <p className="arrival-text">
          Arrive at Airport:{" "}
          {moment(`${editableLeg.arrival_date}T${editableLeg.arrival_time}`).format("hh:mm a")}
        </p>
      </div>
    </div>
  )
}

PickupTimeContent.propTypes = {
  tripHash: PropTypes.string,
  editableLeg: PropTypes.instanceOf(Object),
  segmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PickupTimeContent.defaultProps = {
  tripHash: "",
  editableLeg: {},
  segmentID: "",
}

export default PickupTimeContent
