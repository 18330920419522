import "./Checkbox.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

const Checkbox = ({ checked, onChange, value, disabled, margin, id, largerText }) => {
  const checkboxClass = classNames("checkbox-container", { disabled })
  const textStyle = classNames("checkbox-text body2", { largerText })

  const [isChecked, setChecked] = useState(checked)

  const controlledChecked = typeof checked === "undefined" ? isChecked : checked

  const handleChange = e => {
    if (disabled) return
    if (onChange) {
      onChange(e)
      return
    }
    setChecked(e.currentTarget.checked)
  }

  return (
    <div className={checkboxClass} style={{ margin }}>
      <input
        type="checkbox"
        checked={controlledChecked}
        onChange={handleChange}
        id={id}
        className="input-checkbox"
      />
      <label htmlFor={id} />
      {value && (
        <label htmlFor={id} className={textStyle}>
          {value}
        </label>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  largerText: PropTypes.bool,
}

Checkbox.defaultProps = {
  checked: undefined,
  onChange: () => null,
  disabled: false,
  margin: undefined,
  value: "",
  id: "checkbox",
  largerText: false,
}

export default Checkbox
