import "./UserAvatarName.scss"

import PropTypes from "prop-types"
import React from "react"
import { useNavigate } from "react-router"

import { getFirstChar } from "../../../utils/common"

const UserAvatarName = ({
  firstName,
  lastName,
  avatar,
  avatarSize,
  avatarFontSize,
  nameFontStyle,
}) => {
  const navigate = useNavigate()

  return (
    <div className="avatar-name-container" role="button" onClick={() => navigate("/")}>
      {avatar ? (
        <img src={avatar} width={avatarSize} height={avatarSize} alt="Avatar" />
      ) : (
        <div className="default-avatar" style={{ width: avatarSize, height: avatarSize }}>
          <p className="default-avatar-text" style={{ fontSize: avatarFontSize }}>{`${getFirstChar(
            firstName,
          )}${getFirstChar(lastName)}`}</p>
        </div>
      )}

      <div className="user-name-role-wrapper">
        <p className="user-name" style={nameFontStyle}>
          {firstName}
        </p>
        <p className="user-name" style={nameFontStyle}>
          {lastName}
        </p>
      </div>
    </div>
  )
}

UserAvatarName.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  avatarFontSize: PropTypes.number,
  nameFontStyle: PropTypes.instanceOf(Object),
}

UserAvatarName.defaultProps = {
  firstName: "",
  lastName: "",
  avatar: "",
  avatarSize: 37,
  avatarFontSize: 15,
  nameFontStyle: {},
}

export default UserAvatarName
