import "./LegConnectionVertical.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { INVALID_ADDRESS, MISSING_INFO, VALID_ADDRESS } from "../../../constants/addressStatus"
import API from "../../../constants/API"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { BLACK_CAR_TYPE } from "../../../constants/transportationTypes"
import { clearAddressList, getAddress } from "../../../redux/address/addressActions"
import {
  sendConfirmationEmail,
  setEditLegID,
  updatePickupDetails,
} from "../../../redux/trip/tripActions"
import apiUtils from "../../../utils/api/apiUtils"
import { isEmptyObject } from "../../../utils/common"
import { useDebounce } from "../../../utils/hooks"
import { containsFlight, isBlackCar, isExpress, isExpressHome } from "../../../utils/tripUtils"
import AddressSearchInfo from "../AddressSearchInfo"
import Button from "../Button"
import Checkbox from "../Checkbox"
import SearchDropdown from "../SearchDropdown"
import SvgIcon from "../SvgIcon"
import TimePicker from "../TimePicker"

const LegConnectionVertical = ({
  segments,
  layoverTimes,
  inputDateFormat,
  isDepart,
  isReturnTrip,
  tripHash,
  editing,
  setEditing,
  alreadyEdited,
  setAlreadyEdited,
  segmentID,
  reservationID,
}) => {
  const legconnectionverticalClass = classNames("legconnectionvertical-wrapper", { isReturnTrip })
  const additionalInfoGrid = classNames("grid-container", {})

  const [addressSearch, setAddressSearch] = useState("")
  const dispatch = useDispatch()
  const [chosenAddress, setChosenAddress] = useState({})
  const [otherLegChecked, setOtherLegChecked] = useState(true)

  const isEditing = editing === (isDepart ? SEGMENT_DEPART : SEGMENT_RETURN)

  useEffect(() => {
    if (!isEditing) {
      setOtherLegChecked(true)
      setChosenAddress({})
      setAddressSearch("")
      dispatch(clearAddressList())
    }
  }, [isEditing])

  const [callsInProgress, tripData] = useSelector(({ api, trip }) => [api.callsInProgress, trip])

  const showCheckbox = isDepart
    ? !!tripData.return_editable_leg_id
    : !!tripData.depart_editable_leg_id

  const adressLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_ADDRESS], callsInProgress)
  }, [callsInProgress])

  const updateDetailsLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.UPDATE_PICKUP_DETAILS], callsInProgress)
  }, [callsInProgress])

  const handleNoValue = useCallback(() => {
    dispatch(clearAddressList())
  }, [])

  const [addressList] = useSelector(({ address }) => [address.addressList])

  const isLast = index => {
    if (index === segments.length - 1) {
      return true
    }
    return false
  }
  const isFirst = index => {
    if (index === 0) {
      return true
    }
    return false
  }

  const getAirlineIcon = segment => {
    if (containsFlight([segment])) {
      return (
        <SvgIcon noAbsolute icon="newAirplane" height={30} width={30} fill="#000" withoutProps />
      )
    }
    if (isExpress([segment]) || isExpressHome([segment])) {
      return <SvgIcon noAbsolute withoutProps icon="express" height={10} width={27} />
    }
    if (isBlackCar([segment])) {
      return <SvgIcon noAbsolute icon="blackCar" width={33} height={30} withoutProps />
    }

    return <SvgIcon noAbsolute icon="bus" height={30} width={30} withoutProps />
  }

  const editableLegID = segments.find(
    segment =>
      segment.is_editable &&
      segment.service_type === BLACK_CAR_TYPE &&
      (segment.depart.virtual || segment.arrive.virtual),
  )?.id

  const handleGetAddress = useCallback(() => {
    if (addressSearch.length > 2) {
      const segmentToEdit = segments.find(segment => segment.is_editable)
      dispatch(
        getAddress(
          addressSearch,
          tripHash,
          segmentToEdit.depart.virtual ? segmentToEdit.depart.iata : segmentToEdit.arrive.iata,
        ),
      )
    }
  }, [tripHash, addressSearch])

  useDebounce({
    value: addressSearch,
    callback: handleGetAddress,
    noValueCallback: handleNoValue,
  })

  const getDurationTime = durationTime => {
    const momentTime = moment(durationTime, "HH:mm:ss")
    if (momentTime.minutes() === 0) {
      return momentTime.format("H[h]")
    }
    if (momentTime.hours() === 0) {
      return momentTime.format("m[m]")
    }
    return momentTime.format("H[h] m[m]")
  }

  useEffect(() => {
    dispatch(setEditLegID(isDepart ? "depart" : "return", editableLegID))
  }, [editableLegID])

  const handleSubmit = () => {
    const segmentToEdit = segments.find(segment => segment.is_editable)
    const direction = segmentToEdit.depart.virtual ? "pick_up_address" : "drop_off_address"
    const city = segmentToEdit.depart.virtual
      ? segmentToEdit.depart.city
      : segmentToEdit.arrive.city

    const data = {
      city,
      first_address_line: chosenAddress.description,
      state: chosenAddress.terms[chosenAddress.terms.length - 2].value,
    }

    const editedArr = [isDepart ? SEGMENT_DEPART : SEGMENT_RETURN]

    if (showCheckbox && otherLegChecked) {
      editedArr.push(isDepart ? SEGMENT_RETURN : SEGMENT_DEPART)
    }

    dispatch(
      updatePickupDetails({
        legID: segmentToEdit.id,
        direction,
        data,
        tripHash,
        segment: isDepart ? SEGMENT_DEPART : SEGMENT_RETURN,
        updateOtherSegment: !!(showCheckbox && otherLegChecked),
        otherSegmentID: isDepart
          ? tripData.return_editable_leg_id
          : tripData.depart_editable_leg_id,
        callback: () => {
          setOtherLegChecked(true)
          setChosenAddress({})
          setAddressSearch("")
          dispatch(clearAddressList())
          setEditing(undefined)
          setAlreadyEdited(editedArr)
          dispatch(sendConfirmationEmail(reservationID, tripHash))
        },
      }),
    )
  }

  const checkIfEmptyOrSlash = string => {
    if (string && string !== "/") {
      return string
    }

    return ""
  }

  const getSegmentAddress = (segment, firstLeg) => {
    const getAddressDetails = field => {
      if (firstLeg) {
        return checkIfEmptyOrSlash(segment.pick_up_details?.pick_up_address[field])
      }

      return checkIfEmptyOrSlash(segment.pick_up_details?.drop_off_address[field])
    }

    if (
      segment.id === editableLegID &&
      (firstLeg ? segment.depart?.virtual : segment.arrive?.virtual)
    ) {
      return isEditing ? (
        <div className="search-checkbox-wrapper">
          <div className="search-cancel-wrapper">
            <SearchDropdown
              placeholder="Enter address"
              value={!isEmptyObject(chosenAddress) ? chosenAddress.description : addressSearch}
              onFocus={() => {}}
              setValue={val => {
                setChosenAddress({})
                setAddressSearch(val)
              }}
              items={addressList}
              showItemField="description"
              textInputStyle="address-style"
              onItemClick={address => {
                setChosenAddress(address)
              }}
              loading={adressLoading}
            />
            <div
              className="cancel-icon-wrapper"
              role="button"
              onClick={() => {
                setEditing(undefined)
              }}
            >
              <SvgIcon icon="close" width={30} height={30} />
            </div>
          </div>

          {showCheckbox && (
            <div
              className="checkbox-wrapper"
              role="button"
              onClick={() => setOtherLegChecked(!otherLegChecked)}
            >
              <Checkbox
                value={`${isDepart ? "Return" : "Depart"} leg at the same home service address`}
                checked={otherLegChecked}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className="clickable-address"
          role="button"
          onClick={() => setEditing(isDepart ? SEGMENT_DEPART : SEGMENT_RETURN)}
        >
          <div>
            <div className="address-details">{getAddressDetails("first_address_line")}</div>
            <div className="address-details">{getAddressDetails("city")}</div>
          </div>

          <div className="edit-icon-wrapper">
            <SvgIcon icon="edit-icon" width={30} height={30} />
          </div>
        </div>
      )
    }

    return (
      <div className="clickable-address disabled">
        <div>
          <div className="address-details">{getAddressDetails("first_address_line")}</div>
          <div className="address-details">{getAddressDetails("city")}</div>
        </div>
      </div>
    )
  }

  const getAddressInfo = (segment, firstLeg) => {
    if (isEditing || segment.id !== editableLegID || !segment.pick_up_details) return null

    const checkAddress = address => {
      return address && address !== "/"
    }

    if (
      firstLeg
        ? !checkAddress(segment.pick_up_details?.pick_up_address.first_address_line)
        : !checkAddress(segment.pick_up_details?.drop_off_address.first_address_line)
    ) {
      return <AddressSearchInfo status={MISSING_INFO} firstLeg={firstLeg} />
    }

    if (segment.pick_up_details?.allowed_radius > 0) {
      return <AddressSearchInfo status={INVALID_ADDRESS} firstLeg={firstLeg} />
    }

    if (alreadyEdited.includes(isDepart ? SEGMENT_DEPART : SEGMENT_RETURN)) {
      return <AddressSearchInfo status={VALID_ADDRESS} firstLeg={firstLeg} />
    }

    return null
  }

  return (
    <div className={legconnectionverticalClass}>
      <div className="date-direction-wrapper">
        <div className="dateWrapper">
          {moment(
            `${segments[0].departure_date}T${segments[0].departure_time}`,
            inputDateFormat,
          ).format("dddd, MMMM DD, YYYY")}
        </div>
        <div>{isDepart ? "DEPART" : "RETURN"}</div>
      </div>

      <div className="legConnection-content-container">
        {segments.map((item, index) => {
          return (
            <div className="additional-wrapper" key={item.departure}>
              {isFirst(index) && getAddressInfo(item, isFirst(index))}
              <div className={additionalInfoGrid}>
                {isFirst(index) ? (
                  <>
                    <div className="points-wrapper first-point">
                      <StartPoint />
                      <div className="fillLine" />
                    </div>

                    <div className="legConnection-vertical-grid-item">
                      <div className="name-container">
                        <div className="name-date-wrapper">
                          <div className="name-wrapper">{`${
                            item.departure || item.depart.city
                          }`}</div>
                          <TimePicker
                            date={item.departure_date}
                            time={item.departure_time}
                            isEditable={
                              item.is_editable &&
                              item.service_type === BLACK_CAR_TYPE &&
                              (item.depart.virtual || item.arrive.virtual)
                            }
                            isFirst={isFirst(index)}
                            offsetDetails={item.pick_up_details?.offset_details}
                            tripHash={tripHash}
                            legID={item.id}
                            setEditing={setEditing}
                            isDepart={isDepart}
                            segmentID={segmentID}
                          />
                        </div>
                        {getSegmentAddress(item, isFirst(index))}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="connectedPointWrapper">
                      <div className="iconContainer lastIcon">
                        <SvgIcon
                          icon="LegConnectionLowerConnectionPoint"
                          noAbsolute
                          width={15}
                          height={42}
                          withoutProps
                        />
                      </div>
                      <div className="cityCodeContainer">
                        {`${item.departure || item.depart.city}`}
                        <div className="layover-container">
                          <SvgIcon icon="clock" height={8} width={8} margin="0px 6px 0px 0px" />
                          {getDurationTime(layoverTimes[index - 1])} layover
                        </div>
                      </div>
                    </div>

                    <div className="leftFixedWidth">
                      <div className="lowerDepartTime daysDiffrence">
                        <TimePicker
                          date={item.departure_date}
                          time={item.departure_time}
                          isEditable={
                            item.is_editable &&
                            item.service_type === BLACK_CAR_TYPE &&
                            (item.depart.virtual || item.arrive.virtual)
                          }
                          offsetDetails={item.pick_up_details?.offset_details}
                          tripHash={tripHash}
                          legID={item.id}
                          setEditing={setEditing}
                          isDepart={isDepart}
                          segmentID={segmentID}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="classInfoWrapper">
                <div className="leftFixedWidth" />
                <div className="points-wrapper">
                  <Line />
                </div>
              </div>
              <div className="tripInfoWrapper">
                <div className="iconWrapper">{getAirlineIcon(item)}</div>
              </div>
              <div className="layoverWrapper">
                <div className="leftFixedWidth" />
                <div className="points-wrapper">
                  <Line isBottomLine />
                </div>
              </div>
              {!isLast(index) && (
                <div className="connectedPointWrapper">
                  <div className="iconContainer">
                    <SvgIcon
                      icon="LegConnectionUpperConnectionPoint"
                      noAbsolute
                      width={15}
                      height={42}
                      withoutProps
                    />
                  </div>
                  <div className="" />

                  <div className="leftFixedWidth">
                    <div className="layoverTimeContainer">
                      <div className="upperArrivalTime daysDiffrence">
                        <TimePicker date={item.arrival_date} time={item.arrival_time} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
        <div className="legconnectionvertical-end-dot">
          <div className="end-dot-container">
            <SvgIcon icon="LegConnectionDot" height={15} width={15} noAbsolute withoutProps />
            <div className="fillLine" />
          </div>
          <div className="legConnection-vertical-grid-item">
            <div className="name-container">
              <div className="name-date-wrapper">
                <div className="name-wrapper">{`${
                  segments[segments.length - 1].arrival || segments[segments.length - 1].arrive.city
                }`}</div>

                <div className="leftFixedWidth">
                  <TimePicker
                    date={segments[segments.length - 1].arrival_date}
                    time={segments[segments.length - 1].arrival_time}
                  />
                </div>
              </div>

              {getSegmentAddress(segments[segments.length - 1])}
            </div>
          </div>
        </div>
        {getAddressInfo(segments[segments.length - 1])}
      </div>

      {isEditing && (
        <Button
          text="Confirm"
          margin="30px 0px 0px 0px"
          disabled={!chosenAddress || isEmptyObject(chosenAddress)}
          fullWidth
          onClick={handleSubmit}
          loading={updateDetailsLoading}
        />
      )}
    </div>
  )
}

LegConnectionVertical.propTypes = {
  segments: PropTypes.instanceOf(Array),
  layoverTimes: PropTypes.instanceOf(Array),
  inputDateFormat: PropTypes.string,
  isDepart: PropTypes.bool,
  isReturnTrip: PropTypes.bool,
  tripHash: PropTypes.string,
  editing: PropTypes.number,
  setEditing: PropTypes.func.isRequired,
  alreadyEdited: PropTypes.instanceOf(Array),
  setAlreadyEdited: PropTypes.func,
  segmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reservationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

LegConnectionVertical.defaultProps = {
  segments: [],
  layoverTimes: [],
  inputDateFormat: undefined,
  isDepart: false,
  isReturnTrip: false,
  tripHash: "",
  editing: undefined,
  alreadyEdited: [],
  setAlreadyEdited: () => {},
  segmentID: "",
  reservationID: "",
}

const StartPoint = () => {
  return (
    <div className="dots-point-item">
      <SvgIcon icon="LegConnectionDot" noAbsolute withoutProps height={15} width={15} />
      <div className="bottom-bar" />
    </div>
  )
}

const Line = ({ isBottomLine }) => {
  return (
    <div className="line-wrapper">
      <div className={isBottomLine ? "bottom-line" : "line"} />
    </div>
  )
}

Line.propTypes = {
  isBottomLine: PropTypes.bool,
}
Line.defaultProps = {
  isBottomLine: false,
}

export default LegConnectionVertical
