import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getAddress = (search, tripHash, iata) => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          RESERVATION: tripHash,
        },
      }

      dispatch(startApiCall(API.GET_ADDRESS))
      const response = await landlineApi.get(
        `v2/booking/autocomplete_addresses/${search}/${iata}/`,
        tripHash && config,
      )

      dispatch({ type: ACTIONS.GET_ADDRESS_SUCCESS, addressList: response.data.predictions })
    } catch (error) {
      dispatch(apiError(API.GET_ADDRESS, error))
    }
  }
}

const validateAddress = (address, editableLeg) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.VALIDATE_ADDRESS))

      const data = {
        city: editableLeg.city,
        first_address_line: address.description,
        state: address.terms[address.terms.length - 2].value,
      }

      const response = await landlineApi.post(
        `v2/booking/airports/${editableLeg.iata}/address_radius_validation/`,
        data,
      )

      dispatch({
        type: ACTIONS.VALIDATE_ADDRESS_SUCCESS,
        allowed_radius: response.data.allowed_radius,
      })
    } catch (error) {
      dispatch(apiError(API.VALIDATE_ADDRESS, error))
    }
  }
}

const clearAddressList = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.CLEAR_ADDRESS_LIST_SUCCESS })
  }
}

// eslint-disable-next-line import/prefer-default-export
export { clearAddressList, getAddress, validateAddress }
