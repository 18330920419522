import "./Button.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Loading from "../Loading"

const Button = ({
  onClick,
  text,
  fullWidth,
  disabled,
  margin,
  buttonStyle,
  loading,
  sameHeight,
  smallSize,
  tripsSelection,
  largeSize,
}) => {
  const buttonClass = classNames("button-container", {
    [buttonStyle]: buttonStyle,
    fullWidth,
    disabled,
    loading,
    sameHeight,
    smallSize,
    tripsSelection,
    largeSize,
  })

  return (
    <div className={buttonClass} style={{ margin }}>
      <button disabled={disabled || loading} type="button" onClick={onClick}>
        {loading ? <Loading buttonLoading /> : text}
      </button>
    </div>
  )
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  buttonStyle: PropTypes.oneOf(["primary", "secondary", "red", "white"]),
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  sameHeight: PropTypes.bool,
  smallSize: PropTypes.bool,
  tripsSelection: PropTypes.bool,
  largeSize: PropTypes.bool,
}

Button.defaultProps = {
  buttonStyle: "primary",
  fullWidth: false,
  margin: "",
  disabled: false,
  loading: false,
  sameHeight: false,
  smallSize: false,
  tripsSelection: false,
  largeSize: false,
}

export default Button
