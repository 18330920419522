import "./LegConnectionPreview.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import {
  SHORT_MONTH_DAY_YEAR_WITH_COMMA,
  TWELVE_HOUR_WITH_LEADING_ZERO,
} from "../../../constants/dateFormats"
import SvgIcon from "../SvgIcon"

const LegConnectionPreview = ({ tripDetails, isDepart }) => {
  return (
    <div className="vertical-leg-connection-container">
      {isDepart ? (
        <div className="segment-text">DEPART</div>
      ) : (
        <div className="segment-text return">RETURN</div>
      )}
      <div className="location-row from">
        <div className="side-wrapper">
          <div className="location-icon-wrapper">
            <SvgIcon icon="location-point" height={16} width={15} />
            <div className="connection-line" />
          </div>

          <div>
            <p className="bold-text">From</p>
            <p className="gray-text">{tripDetails[0].depart.AirportName}</p>
          </div>
        </div>

        <div className="right-side-wrapper">
          <p className="date-text">
            {moment(tripDetails[0].departure_time).format(SHORT_MONTH_DAY_YEAR_WITH_COMMA)}
          </p>
          <div className="time-wrapper">
            <SvgIcon icon="green-clock" width={9} height={10} margin="0 3px 0 0" fill="green" />
            <p className="green-text">
              {moment(tripDetails[0].departure_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
            </p>
          </div>
        </div>
      </div>

      <div className="location-row">
        <div className="side-wrapper">
          <div className="location-icon-wrapper">
            <SvgIcon icon="location-point" height={16} width={15} />
            <div className="connection-line bottom" />
          </div>
          <div>
            <p className="bold-text">To</p>
            <p className="gray-text">{tripDetails[tripDetails.length - 1].arrive.AirportName}</p>
          </div>
        </div>

        <div className="right-side-wrapper bottom">
          <div className="time-wrapper">
            <SvgIcon icon="green-clock" width={9} height={10} margin="0 3px 0 0" fill="green" />
            <p className="green-text">
              {moment(tripDetails[tripDetails.length - 1].arrival_time).format(
                TWELVE_HOUR_WITH_LEADING_ZERO,
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

LegConnectionPreview.propTypes = {
  tripDetails: PropTypes.instanceOf(Object),
  isDepart: PropTypes.bool,
}

LegConnectionPreview.defaultProps = {
  tripDetails: {},
  isDepart: false,
}

export default LegConnectionPreview
