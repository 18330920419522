import "./InputField.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"

import { updateFormField } from "../../../redux/forms/formsActions"
import WarningSign from "../WarningSign"

const InputField = ({
  type,
  label,
  placeholder,
  value,
  onChange,
  disabled,
  inputStyle,
  margin,
  icon,
  onIconClick,
  field,
  fullWidth,
  error,
  inputRef,
  name,
  autoComplete,
  isPreview,
  onFocus,
  isModal,
}) => {
  const dispatch = useDispatch()
  const inputWrapperClass = classNames("input-field-wrapper", {
    [inputStyle]: inputStyle,
    disabled,
    fullWidth,
    error,
    isModal,
  })

  const inputClass = classNames("input-field", {
    [inputStyle]: inputStyle,
  })

  const previewClass = classNames("input-preview", {
    [inputStyle]: inputStyle,
  })

  const iconWrapperClass = classNames("icon-wrapper disable-select", {
    clickable: onIconClick,
  })

  const labelClass = classNames("input-field-label", {
    error,
    secondary: inputStyle === "secondary",
  })

  const handleChange = val => {
    if (field) {
      dispatch(updateFormField(field, val))
    } else {
      onChange(val)
    }
  }

  return (
    <div className="input-field-container" style={{ margin }}>
      {label && (
        <div className="input-field-label-wrapper">
          <p className={labelClass}>{label}</p>
          {error && <WarningSign text={error} />}
        </div>
      )}

      <div autoComplete="new-password" className={inputWrapperClass}>
        {isPreview ? (
          <p className={previewClass}>{value}</p>
        ) : (
          <input
            placeholder={placeholder}
            value={value}
            className={inputClass}
            onChange={e => handleChange(e.target.value)}
            disabled={disabled}
            type={type}
            ref={inputRef}
            name={name}
            autoComplete={autoComplete}
            onFocus={onFocus}
          />
        )}

        {icon && (
          <div role="button" onClick={onIconClick} className={iconWrapperClass}>
            {icon}
          </div>
        )}
      </div>
    </div>
  )
}

InputField.propTypes = {
  type: PropTypes.oneOf(["text", "email", "password", "tel"]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  inputStyle: PropTypes.oneOf(["primary", "secondary", "address-style"]),
  margin: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onIconClick: PropTypes.func,
  field: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.string,
  inputRef: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  isPreview: PropTypes.bool,
  onFocus: PropTypes.func,
  isModal: PropTypes.bool,
}

InputField.defaultProps = {
  type: "text",
  placeholder: "",
  value: "",
  onChange: () => {},
  disabled: false,
  inputStyle: "primary",
  margin: "",
  icon: null,
  onIconClick: () => {},
  field: "",
  fullWidth: false,
  error: "",
  inputRef: null,
  name: "",
  autoComplete: "",
  isPreview: false,
  onFocus: () => {},
  label: "",
  isModal: false,
}

export default InputField
