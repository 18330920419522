import "./TimePickerDropdown.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { updateTimeOffset } from "../../../redux/trip/tripActions"
import SvgIcon from "../SvgIcon"

const TimePickerDropdown = ({
  isFirst,
  offsetDetails,
  departureTime,
  tripHash,
  legID,
  setIsOpen,
  isDepart,
  segmentID,
  isModal,
}) => {
  const dispatch = useDispatch()
  const containerClass = classNames("time-picker-dropdown-container", { isFirst, isModal })

  const getTimeIntervals = (startTime, endTime) => {
    const start = moment(startTime, "YYYY-MM-DD hh:mm a")
    const end = moment(endTime, "YYYY-MM-DD hh:mm a")

    const result = []

    const current = moment(start)
    let currentOffset = 0

    if (moment(start).isBefore(moment(end))) {
      while (current <= end) {
        result.push({ value: current.format("YYYY-MM-DD HH:mm"), offset: currentOffset })
        current.add(offsetDetails.increments, "minutes")
        currentOffset += offsetDetails.increments
      }
    } else {
      while (current >= end) {
        result.push({ value: current.format("YYYY-MM-DD HH:mm"), offset: currentOffset })
        current.add(-offsetDetails.increments, "minutes")
        currentOffset += offsetDetails.increments
      }
    }

    return result
  }

  const handleUpdateOffset = useCallback(offset => {
    setIsOpen(false)
    dispatch(
      updateTimeOffset({
        tripHash,
        legID,
        offset,
        segment: isDepart ? SEGMENT_DEPART : SEGMENT_RETURN,
        segmentID,
        noMessage: isModal,
      }),
    )
  }, [])

  const list = getTimeIntervals(offsetDetails.from, offsetDetails.to)
  return (
    <div className={containerClass}>
      {list.map(item => {
        const isSelected = moment(item.value).isSame(moment(departureTime))

        return (
          <div
            className="list-item"
            role="button"
            onClick={() => handleUpdateOffset(item.offset)}
            key={`${item.value}${item.offset}`}
          >
            {moment(item.value).format("hh:mm a")}
            {isSelected && <SvgIcon icon="checkmark" width={12} height={12} />}
          </div>
        )
      })}
    </div>
  )
}

TimePickerDropdown.propTypes = {
  isFirst: PropTypes.bool,
  offsetDetails: PropTypes.instanceOf(Object),
  departureTime: PropTypes.string,
  tripHash: PropTypes.string,
  legID: PropTypes.number,
  setIsOpen: PropTypes.func,
  isDepart: PropTypes.bool,
  segmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isModal: PropTypes.bool,
}

TimePickerDropdown.defaultProps = {
  isFirst: false,
  offsetDetails: {},
  departureTime: "",
  tripHash: "",
  legID: "",
  setIsOpen: () => {},
  isDepart: false,
  segmentID: "",
  isModal: false,
}

export default TimePickerDropdown
