import {
  EXISTING_ACCOUNT,
  FAKE_ACCOUNT,
  FAKE_ACCOUNT_WITH_MULTIPLE_RESERVATIONS,
} from "../constants/accountStatus"

export const getAccountStatus = accountInfo => {
  if (accountInfo.has_usable_password) {
    return EXISTING_ACCOUNT
  }

  if (accountInfo.reservation_count > 1) {
    return FAKE_ACCOUNT_WITH_MULTIPLE_RESERVATIONS
  }

  return FAKE_ACCOUNT
}

export default { getAccountStatus }
