import "./EditTripModalContent.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Loading from "../Loading"
import SvgIcon from "../SvgIcon"

const EditTripModalContent = ({
  icon,
  title,
  children,
  withNextButton,
  onNextButtonClick,
  nextButtonDisabled,
  withInput,
  loading,
  withBackButton,
  onBackButtonClick,
}) => {
  const modalContentContainerClass = classNames("modal-content-container", { withInput })

  if (loading) {
    return <Loading className="page-content-loading" />
  }

  return (
    <div className={modalContentContainerClass}>
      {withBackButton && (
        <div className="previous-button-wrapper" role="button" onClick={onBackButtonClick}>
          <SvgIcon icon="previous-button" width={32} height={32} />
        </div>
      )}

      <div>
        <SvgIcon icon={icon} width={40} height={40} margin="0px 0px 24px 0px" />
      </div>
      <p className="title-text">{title}</p>
      <div style={{ width: "100%" }}>{children}</div>

      {withNextButton && !nextButtonDisabled && (
        <div className="next-button-wrapper" role="button" onClick={onNextButtonClick}>
          <SvgIcon icon="next-button" width={32} height={32} />
        </div>
      )}
    </div>
  )
}

EditTripModalContent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  withNextButton: PropTypes.bool,
  onNextButtonClick: PropTypes.func,
  nextButtonDisabled: PropTypes.bool,
  withInput: PropTypes.bool,
  loading: PropTypes.bool,
  withBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
}

EditTripModalContent.defaultProps = {
  icon: "",
  children: null,
  withNextButton: false,
  onNextButtonClick: () => {},
  nextButtonDisabled: false,
  withInput: false,
  loading: false,
  withBackButton: false,
  onBackButtonClick: () => {},
}

export default EditTripModalContent
