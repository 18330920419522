import jwtDecode from "jwt-decode"
import { toast } from "react-toastify"

import { history } from "../../App"
import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const decodeJWT = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.DECODE_JWT))

      const accessToken = localStorage.getItem("accessToken")
      if (!accessToken) {
        return
      }
      const jwtDecoded = jwtDecode(accessToken)

      dispatch({
        type: ACTIONS.DECODE_JWT_SUCCESS,
        jwtDecoded,
      })
      // eslint-disable-next-line
      return jwtDecoded
    } catch (error) {
      dispatch(apiError(API.DECODE_JWT, error))
    }
  }
}

const getUserProfile = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_USER_PROFILE))

      const response = await landlineApi.get("v2/bossadmin/users/current_user")
      dispatch({
        type: ACTIONS.GET_USER_PROFILE_SUCCESS,
        response: response.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_USER_PROFILE, error))
    }
  }
}

const loginUser = navigate => {
  return async (dispatch, getState) => {
    try {
      dispatch(startApiCall(API.LOGIN_USER))

      const { email, password } = getState().forms.formData

      const response = await landlineApi.post("/sessions/", {
        email,
        password,
      })

      localStorage.setItem("accessToken", response.data.access)
      localStorage.setItem("refreshToken", response.data.refresh)

      const jwtDecoded = await dispatch(decodeJWT())

      sessionStorage.setItem("userRole", jwtDecoded.userRole)

      dispatch({
        type: ACTIONS.LOGIN_USER_SUCCESS,
      })

      navigate("/")

      return undefined
    } catch (error) {
      dispatch(apiError(API.LOGIN_USER, error))
      return error.response?.data?.detail
    }
  }
}

const logoutUser = ({ navigate, warning, isHashTrip }) => {
  return async dispatch => {
    dispatch({
      type: ACTIONS.LOGOUT_USER,
    })

    if (!isHashTrip) {
      if (navigate) {
        navigate("/login")
      } else {
        history.push("/login")
      }
    }

    sessionStorage.removeItem("userRole")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")

    if (warning) {
      toast.warning(warning)
    }
  }
}

const refreshAccessToken = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.REFRESH_ACCESS_TOKEN))

      const refreshToken = localStorage.getItem("refreshToken")
      if (!refreshToken) {
        return
      }
      const response = await landlineApi.post("/refresh_sessions/", { refresh: refreshToken })

      localStorage.setItem("accessToken", response.data.access)
      localStorage.setItem("refreshToken", response.data.refresh)

      await dispatch(decodeJWT())

      dispatch({
        type: ACTIONS.REFRESH_ACCESS_TOKEN_SUCCESS,
      })
    } catch (error) {
      dispatch(logoutUser({ navigate: false, warning: "Your session has expired." }))
      dispatch(apiError(API.REFRESH_ACCESS_TOKEN, error))
    }
  }
}

const registerUser = (accountDetails, navigate) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.REGISTER_USER))

      const response = await landlineApi.post("users/", {
        ...accountDetails,
        subscribe_to_newsletter: false,
      })

      localStorage.setItem("accessToken", response.data.access)
      localStorage.setItem("refreshToken", response.data.refresh)

      navigate("/")

      dispatch({
        type: ACTIONS.REGISTER_USER_SUCCESS,
      })
    } catch (error) {
      dispatch(apiError(API.REGISTER_USER, error))
    }
  }
}

const registerUserWithHash = (accountDetails, navigate, hash) => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          reservation: hash,
        },
      }

      dispatch(startApiCall(API.REGISTER_USER))

      const response = await landlineApi.put(
        "v2/accounts/password/add/",
        {
          ...accountDetails,
        },
        config,
      )

      localStorage.setItem("accessToken", response.data.access)
      localStorage.setItem("refreshToken", response.data.refresh)

      navigate("/")

      dispatch({
        type: ACTIONS.REGISTER_USER_SUCCESS,
      })
    } catch (error) {
      dispatch(apiError(API.REGISTER_USER, error))
    }
  }
}

const getAccountInfo = tripHash => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          reservation: tripHash,
        },
      }
      dispatch(startApiCall(API.GET_ACCOUNT_INFO))

      const response = await landlineApi.get("v2/accounts/profile/", tripHash && config)
      dispatch({
        type: ACTIONS.GET_ACCOUNT_INFO_SUCCESS,
        response: response.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_ACCOUNT_INFO, error))
    }
  }
}

export {
  decodeJWT,
  getAccountInfo,
  getUserProfile,
  loginUser,
  logoutUser,
  refreshAccessToken,
  registerUser,
  registerUserWithHash,
}
