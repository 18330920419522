import "./SpecialRequests.scss"

import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { savePassengerNote } from "../../../redux/trip/tripActions"
import ExistingTripNote from "../ExistingTripNote"
import SvgIcon from "../SvgIcon"
import TripNote from "../TripNote"

const SpecialRequests = ({ legID, tripHash, notes, isDepart }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [noteValue, setNoteValue] = useState("")
  const [addNoteOpen, setAddNoteOpen] = useState(false)
  const [editingNote, setEditingNote] = useState(undefined)
  const handleSaveNote = useCallback(() => {
    dispatch(
      savePassengerNote(
        legID,
        tripHash,
        noteValue,
        () => {
          setAddNoteOpen(false)
          setNoteValue("")
        },
        isDepart ? SEGMENT_DEPART : SEGMENT_RETURN,
      ),
    )
  }, [legID, tripHash, noteValue])

  return (
    <div className="special-requests-container">
      <div
        className="special-requests-wrapper"
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setAddNoteOpen(false)
          setNoteValue("")
        }}
      >
        <p className="section-title">Special requests</p>
        <SvgIcon icon={isOpen ? "arrow-up" : "arrow-down"} width={44} height={20} />
      </div>

      {isOpen && (
        <div>
          {!addNoteOpen && (
            <div
              className="add-new-wrapper"
              role="button"
              onClick={() => {
                setAddNoteOpen(true)
                setEditingNote(undefined)
              }}
            >
              <SvgIcon icon="cross-in-circle" width={24} height={24} />
              <p className="add-new-text">Add new</p>
            </div>
          )}
          {addNoteOpen && (
            <TripNote
              placeholder="Write your requests here"
              value={noteValue}
              onChange={e => setNoteValue(e.target.value)}
              onCancelClick={() => {
                setAddNoteOpen(false)
                setNoteValue("")
              }}
              onSaveClick={handleSaveNote}
            />
          )}
          {notes.length > 0 &&
            notes.map(note => (
              <ExistingTripNote
                key={note.id}
                note={note}
                editing={editingNote}
                setEditing={setEditingNote}
                tripHash={tripHash}
                isDepart={isDepart}
                legID={legID}
                resetAddNote={() => {
                  setAddNoteOpen(false)
                  setNoteValue("")
                }}
              />
            ))}
        </div>
      )}
    </div>
  )
}

SpecialRequests.propTypes = {
  legID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tripHash: PropTypes.string,
  notes: PropTypes.instanceOf(Array),
  isDepart: PropTypes.bool,
}

SpecialRequests.defaultProps = {
  legID: "",
  tripHash: "",
  notes: [],
  isDepart: false,
}

export default SpecialRequests
