import "./AddressWarning.scss"

import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const AddressWarning = ({ icon, text }) => {
  return (
    <div className="address-warning-container">
      <SvgIcon icon={icon} width={17} height={17} />
      <div className="address-text">{text}</div>
    </div>
  )
}

AddressWarning.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

AddressWarning.defaultProps = {}

export default AddressWarning
