import "./TripNote.scss"

import PropTypes from "prop-types"
import React from "react"

const TripNote = ({ value, onChange, onCancelClick, onSaveClick, placeholder }) => {
  return (
    <div className="add-new-body">
      <textarea
        className="add-new-body_content"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        rows="2"
      />

      <div className="add-new-body_actions">
        <span role="button" className="trip-note-button" onClick={onCancelClick}>
          Cancel
        </span>
        <span role="button" className="trip-note-button save" onClick={onSaveClick}>
          Save
        </span>
      </div>
    </div>
  )
}

TripNote.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  placeholder: PropTypes.string,
}

TripNote.defaultProps = {
  value: "",
  onChange: () => {},
  onCancelClick: () => {},
  onSaveClick: () => {},
  placeholder: "Enter note here",
}

export default TripNote
