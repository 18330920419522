import "./AdditionalTripInfo.scss"

import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import { BLACK_CAR_TYPE } from "../../../constants/transportationTypes"
import { switchCheckedBaggage } from "../../../redux/trip/tripActions"
import Checkbox from "../../atoms/Checkbox"
import CustomMap from "../../atoms/CustomMap"
import DriverInformation from "../../atoms/DriverInformation"
import SpecialRequests from "../../atoms/SpecialRequests"

const AdditionalTripInfo = ({ segment, tripHash, isDepart }) => {
  const dispatch = useDispatch()
  const legToShow = segment.legs.find(
    leg => leg.pick_up_details && leg.service_type === BLACK_CAR_TYPE,
  )

  const isEditable =
    legToShow?.is_editable && (legToShow.depart.virtual || legToShow.arrive.virtual)

  if (!legToShow) {
    return null
  }

  const handleCheckedBaggage = useCallback(() => {
    dispatch(switchCheckedBaggage(segment.id, !segment.checked_baggage, tripHash))
  }, [segment.id, segment.checked_baggage, tripHash])

  return (
    <div className="additional-trip-info-container">
      <CustomMap
        pickUpAddress={legToShow.pick_up_details.pick_up_address}
        dropOffAddress={legToShow.pick_up_details.drop_off_address}
      />
      <DriverInformation driverInfo={legToShow.driver} vehicleInfo={legToShow.vehicle} />

      {isEditable && (
        <>
          <div className="checkbox-wrapper" role="button" onClick={handleCheckedBaggage}>
            <Checkbox
              checked={!!segment.checked_baggage}
              value="Are you travelling with a checked bag?"
              largerText
            />
          </div>

          <SpecialRequests
            legID={legToShow.id}
            tripHash={tripHash}
            notes={legToShow.notes}
            isDepart={isDepart}
          />
        </>
      )}
    </div>
  )
}

AdditionalTripInfo.propTypes = {
  segment: PropTypes.instanceOf(Object),
  tripHash: PropTypes.string,
  isDepart: PropTypes.bool,
}

AdditionalTripInfo.defaultProps = {
  segment: {},
  tripHash: "",
  isDepart: false,
}

export default AdditionalTripInfo
