import "./EditTripModal.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { clearAddressList } from "../../../redux/address/addressActions"
import {
  savePassengerNote,
  sendConfirmationEmail,
  switchCheckedBaggage,
  updatePickupDetails,
} from "../../../redux/trip/tripActions"
import apiUtils from "../../../utils/api/apiUtils"
import AddressContent from "../../atoms/AddressContent"
import Button from "../../atoms/Button"
import EditTripModalContent from "../../atoms/EditTripModalContent"
import PickupTimeContent from "../../atoms/PickupTimeContent"
import Modal from "../Modal"

const EditTripModal = ({
  isOpen,
  setIsOpen,
  tripHash,
  departureLegs,
  hasEditableReturn,
  returnLegs,
  departureSegmentID,
  returnSegmentID,
  passengerName,
  reservationID,
}) => {
  const dispatch = useDispatch()
  const [index, setIndex] = useState(0)
  const [activeBaggageButton, setActiveBaggageButton] = useState(undefined)
  const [chosenAddress, setChosenAddress] = useState({})
  const [previouslyUsedAddress, setPreviouslyUsedAddress] = useState("")
  const [specialNote, setSpecialNote] = useState("")

  const departEditableLeg = departureLegs.find(leg => leg.is_editable)
  const returnEditableLeg = returnLegs && returnLegs.find(leg => leg.is_editable)

  const isPickup = departEditableLeg
    ? departureLegs.findIndex(leg => leg.id === departEditableLeg?.id) === 0
    : returnLegs.findIndex(leg => leg.id === returnEditableLeg?.id) === 0

  const [callsInProgress, validatedAllowedRadius] = useSelector(({ api, address }) => [
    api.callsInProgress,
    address.validatedAllowedRadius,
  ])

  const baggageLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.SWITCH_CHECKED_BAGGAGE], callsInProgress)
  }, [callsInProgress])

  const specialNoteLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.SAVE_PASSENGER_NOTE], callsInProgress)
  }, [callsInProgress])

  const addressValidationLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.VALIDATE_ADDRESS], callsInProgress)
  }, [callsInProgress])

  const pickupLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.UPDATE_PICKUP_DETAILS], callsInProgress)
  }, [callsInProgress])

  const handleSubmit = () => {
    if (previouslyUsedAddress === chosenAddress.description) {
      setIndex(index + 1)
    } else {
      const legToEdit = departEditableLeg || returnEditableLeg
      const direction = legToEdit.depart.virtual ? "pick_up_address" : "drop_off_address"
      const city = legToEdit.depart.virtual ? legToEdit.depart.city : legToEdit.arrive.city

      const data = {
        city,
        first_address_line: chosenAddress.description,
        state: chosenAddress.terms[chosenAddress.terms.length - 2].value,
      }

      dispatch(
        updatePickupDetails({
          legID: legToEdit.id,
          direction,
          data,
          tripHash,
          segment: departEditableLeg ? SEGMENT_DEPART : SEGMENT_RETURN,
          updateOtherSegment: hasEditableReturn && departEditableLeg,
          otherSegmentID: returnEditableLeg?.id,
          callback: () => {
            dispatch(clearAddressList())
            setIndex(index + 1)
            setPreviouslyUsedAddress(chosenAddress.description)
          },
          withoutSuccessMessage: true,
        }),
      )
    }
  }

  const handleSwitchBaggage = useCallback(
    checked => {
      dispatch(
        switchCheckedBaggage(
          departEditableLeg ? departureSegmentID : returnSegmentID,
          checked,
          tripHash,
          () => {
            if (hasEditableReturn && departEditableLeg) {
              dispatch(switchCheckedBaggage(returnSegmentID, checked, tripHash))
            }
          },
          () => setIndex(index + 1),
        ),
      )
    },
    [index],
  )

  const getModalContent = () => {
    const contentArray = [
      <div className="edit-trip-content-container">
        <EditTripModalContent title={`Welcome, ${passengerName}!`}>
          <div className="welcome-content">
            <p className="additional-text">Just a few questions before we complete your booking</p>

            <Button
              largeSize
              buttonStyle="primary"
              text="Next"
              fullWidth
              margin="40px 0px 0px 0px"
              onClick={() => setIndex(index + 1)}
            />
          </div>
        </EditTripModalContent>
      </div>,
      <div className="edit-trip-content-container">
        <EditTripModalContent
          title="Are you travelling with a checked bag?"
          withNextButton
          nextButtonDisabled={!activeBaggageButton}
          loading={baggageLoading}
          onNextButtonClick={() => setIndex(index + 1)}
          icon="checked-baggage"
        >
          <CheckedBaggageContent
            activeButton={activeBaggageButton}
            setActiveButton={setActiveBaggageButton}
            onButtonClick={handleSwitchBaggage}
          />
        </EditTripModalContent>
      </div>,
      <div className="edit-trip-content-container">
        <EditTripModalContent
          title={`Please enter ${isPickup ? "pick up" : "drop off"} address below`}
          withNextButton={!addressValidationLoading && chosenAddress && validatedAllowedRadius <= 0}
          withInput
          onNextButtonClick={handleSubmit}
          icon="address-sign"
          withBackButton
          onBackButtonClick={() => {
            setIndex(index - 1)
          }}
          loading={pickupLoading}
        >
          <AddressContent
            chosenAddress={chosenAddress}
            setChosenAddress={setChosenAddress}
            tripHash={tripHash}
            validatedAllowedRadius={validatedAllowedRadius}
            addressValidationLoading={addressValidationLoading}
            isPickup={isPickup}
            editableLeg={departEditableLeg || returnEditableLeg}
          />
        </EditTripModalContent>
      </div>,
      <div className="edit-trip-content-container">
        <EditTripModalContent
          title="When would you like to be picked up?"
          withNextButton
          withInput
          nextButtonDisabled={!activeBaggageButton}
          onNextButtonClick={() => setIndex(index + 1)}
          icon="large-clock"
          withBackButton
          onBackButtonClick={() => {
            setIndex(index - 1)
          }}
        >
          <PickupTimeContent
            editableLeg={departEditableLeg || returnEditableLeg}
            tripHash={tripHash}
            segmentID={departEditableLeg ? departureSegmentID : returnSegmentID}
          />
        </EditTripModalContent>
      </div>,
      <div className="edit-trip-content-container">
        <EditTripModalContent
          title="Do you have any special requests?"
          withInput
          icon="special-note"
          loading={specialNoteLoading}
          withBackButton
          onBackButtonClick={() => {
            setIndex(index - 1)
          }}
        >
          <SpecialNoteContent
            onButtonClick={() => {
              if (specialNote) {
                dispatch(
                  savePassengerNote(
                    departEditableLeg ? departEditableLeg.id : returnEditableLeg.id,
                    tripHash,
                    specialNote,
                    () => {
                      if (hasEditableReturn && departEditableLeg) {
                        dispatch(
                          savePassengerNote(
                            returnEditableLeg.id,
                            tripHash,
                            specialNote,
                            () => {},
                            SEGMENT_RETURN,
                            true,
                          ),
                        )
                      }
                    },
                    departEditableLeg ? SEGMENT_DEPART : SEGMENT_RETURN,
                    true,
                    () => {
                      dispatch(sendConfirmationEmail(reservationID, tripHash))
                      setIndex(index + 1)
                    },
                  ),
                )
              } else {
                dispatch(sendConfirmationEmail(reservationID, tripHash))
                setIndex(index + 1)
              }
            }}
            specialNote={specialNote}
            setSpecialNote={setSpecialNote}
          />
        </EditTripModalContent>
      </div>,
      <div className="edit-trip-content-container">
        <EditTripModalContent
          title="Your booking has been completed successfully!"
          withInput
          icon="confirmation-icon"
        >
          <ConfirmationContent onButtonClick={() => setIsOpen(false)} />
        </EditTripModalContent>
      </div>,
    ]

    return contentArray[index]
  }

  return (
    <div className="edit-trip-modal-container">
      <Modal
        onClick={e => e.stopPropagation()}
        render={() => getModalContent()}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 310,
            height: 430,
            display: "flex",
            overflow: "none",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            backgroundColor: "#ffffff",
            padding: 0,
          },
        }}
      />
    </div>
  )
}

EditTripModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  tripHash: PropTypes.string,
  departureLegs: PropTypes.instanceOf(Array),
  returnLegs: PropTypes.instanceOf(Array),
  departureSegmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  returnSegmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasEditableReturn: PropTypes.bool,
  passengerName: PropTypes.string,
  reservationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EditTripModal.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  tripHash: "",
  departureLegs: [],
  returnLegs: [],
  departureSegmentID: "",
  returnSegmentID: "",
  hasEditableReturn: false,
  passengerName: "",
  reservationID: "",
}

const CheckedBaggageContent = ({ activeButton, setActiveButton, onButtonClick }) => {
  const yesButtonClass = classNames("checked-bag-button", { active: activeButton === 1 })
  const noButtonClass = classNames("checked-bag-button right", { active: activeButton === 2 })

  return (
    <div className="checked-baggage-container">
      <div className="buttons-wrapper">
        <div
          className={yesButtonClass}
          role="button"
          onClick={() => {
            onButtonClick(true)
            setActiveButton(1)
          }}
        >
          Yes
        </div>
        <div
          className={noButtonClass}
          role="button"
          onClick={() => {
            onButtonClick(false)
            setActiveButton(2)
          }}
        >
          No
        </div>
      </div>
    </div>
  )
}

CheckedBaggageContent.propTypes = {
  activeButton: PropTypes.number,
  setActiveButton: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
}

CheckedBaggageContent.defaultProps = {
  activeButton: undefined,
  onButtonClick: () => {},
}

const SpecialNoteContent = ({ onButtonClick, specialNote, setSpecialNote }) => {
  return (
    <>
      <textarea
        className="special-note-container"
        onChange={e => setSpecialNote(e.target.value)}
        value={specialNote}
        placeholder="Write your requests here"
        rows="2"
      />

      <Button text="Finish" largeSize fullWidth margin="24px 0px 0px 0px" onClick={onButtonClick} />
    </>
  )
}

SpecialNoteContent.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  specialNote: PropTypes.string.isRequired,
  setSpecialNote: PropTypes.func.isRequired,
}

const ConfirmationContent = ({ onButtonClick }) => {
  return (
    <div className="confirmation-content-container">
      <p className="confirmation-text">
        You can come back to change your address, and departure time, or add special requests up to
        24 hours before your departure.
      </p>

      <Button text="Close" largeSize fullWidth margin="48px 0px 0px 0px" onClick={onButtonClick} />
    </div>
  )
}

ConfirmationContent.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
}

export default EditTripModal
