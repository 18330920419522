import React from "react"
import { Navigate, useParams } from "react-router"

const ReservationRedirection = () => {
  const { tripHash } = useParams()

  return <Navigate to={`/reservation/${encodeURIComponent(tripHash)}`} />
}

ReservationRedirection.propTypes = {}

ReservationRedirection.defaultProps = {}

export default ReservationRedirection
