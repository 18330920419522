import PropTypes from "prop-types"
import React from "react"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"

const AuthenticatedRoute = ({ children }) => {
  const checkToken = () => {
    const accessToken = localStorage.getItem("accessToken")
    if (!accessToken) {
      toast.warning("You must login first.", { toastId: "login-message" })
      return <Navigate to="/login" />
    }

    return children
  }

  return checkToken()
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.instanceOf(Object),
}

export default AuthenticatedRoute
