import "./AccountActionSuggestion.scss"

import PropTypes from "prop-types"
import React from "react"
import { useNavigate } from "react-router"

import {
  EXISTING_ACCOUNT,
  FAKE_ACCOUNT,
  FAKE_ACCOUNT_WITH_MULTIPLE_RESERVATIONS,
} from "../../../constants/accountStatus"
import { getAccountStatus } from "../../../utils/accountUtils"
import Button from "../Button"

const AccountActionSuggestion = ({ accountInfo, tripHash }) => {
  const navigate = useNavigate()
  const getAccountSuggestion = () => {
    const status = getAccountStatus(accountInfo)

    let text
    let buttonStyle
    let buttonText
    let callback

    switch (status) {
      case EXISTING_ACCOUNT:
        text = "Login to see your upcoming trips"
        buttonStyle = "primary"
        buttonText = "Login"
        callback = () => navigate("/login")
        break

      case FAKE_ACCOUNT_WITH_MULTIPLE_RESERVATIONS:
        text =
          "It looks like you've used Landline before. Create an account to have all of your rides in one place"
        buttonStyle = "secondary"
        buttonText = "Create account"
        callback = () => navigate(`/register/${encodeURIComponent(tripHash)}`)
        break

      case FAKE_ACCOUNT:
        text = "Register a Landline account to have all your rides in one place"
        buttonStyle = "secondary"
        buttonText = "Create account"
        callback = () => navigate(`/register/${encodeURIComponent(tripHash)}`)
        break

      default:
        break
    }

    return { text, buttonStyle, buttonText, callback }
  }

  return (
    <div className="account-suggestion-container">
      <div className="text-wrapper">{getAccountSuggestion().text}</div>
      <Button
        buttonStyle={getAccountSuggestion().buttonStyle}
        text={getAccountSuggestion().buttonText}
        margin="0px 0px 42px 0px"
        fullWidth
        onClick={getAccountSuggestion().callback}
      />
    </div>
  )
}

AccountActionSuggestion.propTypes = {
  accountInfo: PropTypes.instanceOf(Object),
  tripHash: PropTypes.string,
}

AccountActionSuggestion.defaultProps = {
  accountInfo: {},
  tripHash: "",
}

export default AccountActionSuggestion
