export default {
  // api actions
  START_API_CALL: "START_API_CALL",
  API_ERROR: "API_ERROR",
  CLEAR_API_ERROR: "CLEAR_API_ERROR",

  // form actions
  UPDATE_FORM_FIELD: "UPDATE_FORM_FIELD",
  UPDATE_FORM_FIELDS: "UPDATE_FORM_FIELDS",
  INIT_FORM: "INIT_FORM",
  CLEAR_FORM: "CLEAR_FORM",
  SET_FORM_ERRORS: "SET_FORM_ERRORS",
  CLEAR_FORM_ERRORS: "CLEAR_FORM_ERRORS",
  SET_ACTIVE_FIELD: "SET_ACTIVE_FIELD",
  SET_FORM_SENT: "SET_FORM_SENT",
  FORM_SENT_RESET: "FORM_SENT_RESET",
  SET_FORM_RESPONSE: "SET_FORM_RESPONSE",

  // user
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGOUT_USER: "LOGOUT_USER",
  REFRESH_ACCESS_TOKEN_SUCCESS: "REFRESH_ACCESS_TOKEN_SUCCESS",
  DECODE_JWT_SUCCESS: "DECODE_JWT_SUCCESS",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_ACCOUNT_INFO_SUCCESS: "GET_ACCOUNT_INFO_SUCCESS",

  // trip actions
  GET_UPCOMING_TRIPS_SUCCESS: "GET_UPCOMING_TRIPS_SUCCESS",
  GET_PAST_TRIPS_SUCCESS: "GET_PAST_TRIPS_SUCCESS",
  GET_TRIP_DETAILS_SUCCESS: "GET_TRIP_DETAILS_SUCCESS",
  GET_NEXT_TRIPS_PAGE_SUCCESS: "GET_NEXT_TRIPS_PAGE_SUCCESS",
  START_TRIP_SUCCESS: "START_TRIP_SUCCESS",
  COMPLETE_TRIP_SUCCESS: "COMPLETE_TRIP_SUCCESS",
  RESET_TRIP_STATE_SUCCESS: "RESET_TRIP_STATE_SUCCESS",
  GET_HASH_TRIP_SUCCESS: "GET_HASH_TRIP_SUCCESS",
  UPDATE_PICKUP_DETAILS_SUCCESS: "UPDATE_PICKUP_DETAILS_SUCCESS",
  SET_EDIT_LEG_ID_SUCCESS: "SET_EDIT_LEG_ID_SUCCESS",
  SWITCH_CHECKED_BAGGAGE_SUCCESS: "SWITCH_CHECKED_BAGGAGE_SUCCESS",
  GET_TRIP_BY_PNR_SUCCESS: "GET_TRIP_BY_PNR_SUCCESS",
  CLEAR_SELECTED_TRIP_SUCCESS: "CLEAR_SELECTED_TRIP_SUCCESS",
  UPDATE_TIME_OFFSET_SUCCESS: "UPDATE_TIME_OFFSET_SUCCESS",
  SAVE_PASSENGER_NOTE_SUCCESS: "SAVE_PASSENGER_NOTE_SUCCESS",
  UPDATE_PASSENGER_NOTE_SUCCESS: "UPDATE_PASSENGER_NOTE_SUCCESS",
  DELETE_PASSENGER_NOTE_SUCCESS: "DELETE_PASSENGER_NOTE_SUCCESS",
  SET_TIMESTAMP: "SET_TIMESTAMP",
  SEND_CONFIRMATION_EMAIL_SUCCESS: "SEND_CONFIRMATION_EMAIL_SUCCESS",

  // address actions
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  CLEAR_ADDRESS_LIST_SUCCESS: "CLEAR_ADDRESS_LIST_SUCCESS",
  VALIDATE_ADDRESS_SUCCESS: "VALIDATE_ADDRESS_SUCCESS",
}
