import "./TripDetails.scss"

import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import API from "../../../constants/API"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { BLACK_CAR_TYPE } from "../../../constants/transportationTypes"
import apiUtils from "../../../utils/api/apiUtils"
import { getTripDirection } from "../../../utils/tripUtils"
import AccountActionSuggestion from "../../atoms/AccountActionSuggestion"
import LegConnectionVertical from "../../atoms/LegConnectionVertical"
import Loading from "../../atoms/Loading"
import AdditionalTripInfo from "../../molecules/AdditionalTripInfo"
import EditTripModal from "../EditTripModal"

const TripDetails = ({ tripHash, accountInfo, loggedIn }) => {
  const [tripData, callsInProgress] = useSelector(({ trip, api }) => [
    trip.selectedTrip,
    api.callsInProgress,
  ])
  const [editing, setEditing] = useState(undefined)
  const [alreadyEdited, setAlreadyEdited] = useState([])

  const [editTripModalOpen, setEditTripModalOpen] = useState(false)

  const checkAddress = () => {
    if (!tripData || tripData.is_past_trip) {
      return null
    }

    const allLegs = [...tripData.segments[0].legs]

    if (tripData.segments[1]) {
      allLegs.push(...tripData.segments[1].legs)
    }

    const allEditableLegs = allLegs.filter(
      leg =>
        leg.is_editable &&
        leg.service_type === BLACK_CAR_TYPE &&
        (leg.depart.virtual || leg.arrive.virtual),
    )

    if (allEditableLegs.length === 0) {
      return null
    }

    const checkIfMissingAddress = leg => {
      if (leg.depart.virtual) {
        return (
          leg.pick_up_details.pick_up_address.first_address_line === "" ||
          leg.pick_up_details.pick_up_address.first_address_line === "/"
        )
      }

      return (
        leg.pick_up_details.drop_off_address.first_address_line === "" ||
        leg.pick_up_details.drop_off_address.first_address_line === "/"
      )
    }

    if (allEditableLegs.filter(leg => !checkIfMissingAddress(leg)).length === 0) {
      setEditTripModalOpen(true)
    }

    return null
  }

  const checkIfReturnIsEditable = () => {
    if (tripData.segments[1]) {
      return !!tripData.segments[1].legs.find(
        leg =>
          leg.is_editable &&
          leg.service_type === BLACK_CAR_TYPE &&
          (leg.depart.virtual || leg.arrive.virtual),
      )
    }

    return false
  }

  useEffect(() => {
    if (!editTripModalOpen) {
      checkAddress()
    }
  }, [tripData])

  const tripLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_HASH_TRIP, API.GET_TRIP_BY_PNR], callsInProgress)
  }, [callsInProgress])

  const accountInfoLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_ACCOUNT_INFO], callsInProgress)
  }, [callsInProgress])

  const getTripNotFoundContent = () => {
    if (tripLoading) {
      return <Loading className="page-content-loading" />
    }

    return <div className="not-found">Trip was not found.</div>
  }

  return (
    <div className="trip-details-container">
      {!tripLoading && tripData ? (
        <>
          <div className="top-row-container">
            <p className="pnr-text">{tripData?.rloc}</p>
          </div>

          <div>
            <LegConnectionVertical
              segments={getTripDirection(tripData, SEGMENT_DEPART).segments}
              layoverTimes={getTripDirection(tripData, SEGMENT_DEPART).layoverTimes}
              inputDateFormat="YYYY-MM-DDTHH:mm"
              isReturnTrip={tripData.segments.length > 1}
              isDepart
              tripHash={tripHash}
              editing={editing}
              setEditing={setEditing}
              alreadyEdited={alreadyEdited}
              setAlreadyEdited={setAlreadyEdited}
              segmentID={tripData.segments[SEGMENT_DEPART].id}
              reservationID={tripData?.id}
            />

            <AdditionalTripInfo segment={tripData.segments[0]} tripHash={tripHash} isDepart />

            {tripData.segments.length > 1 && (
              <>
                <LegConnectionVertical
                  segments={getTripDirection(tripData, SEGMENT_RETURN).segments}
                  layoverTimes={getTripDirection(tripData, SEGMENT_RETURN).layoverTimes}
                  inputDateFormat="YYYY-MM-DDTHH:mm"
                  tripHash={tripHash}
                  editing={editing}
                  setEditing={setEditing}
                  alreadyEdited={alreadyEdited}
                  setAlreadyEdited={setAlreadyEdited}
                  segmentID={tripData.segments[SEGMENT_RETURN].id}
                  reservationID={tripData?.id}
                />

                <AdditionalTripInfo segment={tripData.segments[1]} tripHash={tripHash} />
              </>
            )}

            {tripHash && !accountInfoLoading && !loggedIn && (
              <AccountActionSuggestion accountInfo={accountInfo} tripHash={tripHash} />
            )}
          </div>

          <EditTripModal
            isOpen={editTripModalOpen}
            setIsOpen={setEditTripModalOpen}
            departureLegs={getTripDirection(tripData, SEGMENT_DEPART).segments}
            returnLegs={
              tripData.segments.length > 1
                ? getTripDirection(tripData, SEGMENT_RETURN).segments
                : []
            }
            tripHash={tripHash}
            departureSegmentID={tripData.segments[SEGMENT_DEPART]?.id}
            returnSegmentID={tripData.segments[SEGMENT_RETURN]?.id}
            hasEditableReturn={checkIfReturnIsEditable()}
            passengerName={`${tripData.passengers[0].first_name} ${tripData.passengers[0].last_name}`}
            reservationID={tripData?.id}
          />
        </>
      ) : (
        getTripNotFoundContent()
      )}
    </div>
  )
}

TripDetails.propTypes = {
  tripHash: PropTypes.string,
  accountInfo: PropTypes.instanceOf(Object),
  loggedIn: PropTypes.bool,
}

TripDetails.defaultProps = {
  tripHash: "",
  accountInfo: {},
  loggedIn: false,
}

export default TripDetails
