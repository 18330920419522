import "./AddressContent.scss"

import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  clearAddressList,
  getAddress,
  validateAddress,
} from "../../../redux/address/addressActions"
import { isEmptyObject } from "../../../utils/common"
import { useDebounce } from "../../../utils/hooks"
import Loading from "../Loading"
import SearchDropdown from "../SearchDropdown"
import SvgIcon from "../SvgIcon"

const AddressContent = ({
  chosenAddress,
  setChosenAddress,
  editableLeg,
  tripHash,
  validatedAllowedRadius,
  addressValidationLoading,
  isPickup,
}) => {
  const [addressSearch, setAddressSearch] = useState("")

  const dispatch = useDispatch()

  const [addressList] = useSelector(({ address }) => [address.addressList])

  const handleGetAddress = useCallback(() => {
    if (addressSearch.length > 2) {
      dispatch(
        getAddress(
          addressSearch,
          tripHash,
          editableLeg.depart.virtual ? editableLeg.depart.iata : editableLeg.arrive.iata,
        ),
      )
    }
  }, [tripHash, addressSearch])

  const handleNoValue = useCallback(() => {
    dispatch(clearAddressList())
  }, [])

  useDebounce({
    value: addressSearch,
    callback: handleGetAddress,
    noValueCallback: handleNoValue,
  })

  return (
    <div className="address-content-container">
      <p className="search-label">{`${isPickup ? "Pick up" : "Drop off"} address`}</p>
      <SearchDropdown
        placeholder="Enter address"
        value={!isEmptyObject(chosenAddress) ? chosenAddress.description : addressSearch}
        onFocus={() => {}}
        setValue={val => {
          setChosenAddress(undefined)
          setAddressSearch(val)
        }}
        items={addressList}
        showItemField="description"
        textInputStyle="address-style"
        onItemClick={address => {
          setChosenAddress(address)
          dispatch(
            validateAddress(
              address,
              editableLeg.depart.virtual ? editableLeg.depart : editableLeg.arrive,
            ),
          )
        }}
        isModal
      />

      {addressValidationLoading && <Loading className="page-content-loading" />}

      {validatedAllowedRadius > 0 && !addressValidationLoading && (
        <div className="address-info">
          <SvgIcon icon="invalid-address" height={17} width={17} />
          <p className="address-info-text">The address is outside of the service radius</p>
        </div>
      )}
    </div>
  )
}

AddressContent.propTypes = {
  editableLeg: PropTypes.instanceOf(Object),
  tripHash: PropTypes.string,
  chosenAddress: PropTypes.instanceOf(Object),
  setChosenAddress: PropTypes.func,
  validatedAllowedRadius: PropTypes.number,
  addressValidationLoading: PropTypes.bool,
  isPickup: PropTypes.bool,
}

AddressContent.defaultProps = {
  editableLeg: {},
  tripHash: "",
  chosenAddress: {},
  setChosenAddress: () => {},
  validatedAllowedRadius: undefined,
  addressValidationLoading: false,
  isPickup: false,
}

export default AddressContent
