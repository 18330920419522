import moment from "moment"

import {
  BLACK_CAR_TYPE,
  EXPRESS_HOME_TYPE,
  EXPRESS_TYPE,
  FLIGHT_TYPE,
} from "../constants/transportationTypes"

export const isBlackCarAirline = segment => segment.service_type === BLACK_CAR_TYPE

export const isExpressAirline = segment => segment.service_type === EXPRESS_TYPE

const isExpressHomeAirline = segment => segment.service_type === EXPRESS_HOME_TYPE

export const isBlackCar = (segments = []) => {
  return !!segments.find(segment => isBlackCarAirline(segment))
}

export const isExpress = (segments = []) => {
  return !!segments.find(segment => isExpressAirline(segment))
}

export const isExpressHome = (segments = []) => {
  return !!segments.find(segment => isExpressHomeAirline(segment))
}

export const containsFlight = (segments = []) => {
  return !!segments.find(segment => segment.service_type === FLIGHT_TYPE)
}

export const formatAddress = addressDetails => {
  return `${addressDetails.first_address_line}, ${addressDetails.city}, ${addressDetails.state}${
    addressDetails.zip_code ? `, ${addressDetails.zip_code}` : ""
  }`
}

export const getTripDirection = ({ segments: oldLegs, passengers, tickets }, direction) => {
  let segments = []
  let layoverTimes = []
  if (oldLegs[direction]) {
    layoverTimes = oldLegs[direction].layover_times
    segments = oldLegs[direction].legs.map((segment, index) => {
      return {
        ...segment,
        all_checked_in: oldLegs[direction].all_checked_in,
        arrival: segment.arrive.AirportName,
        arrival_code: segment.arrive.CityCode,
        arrival_date: moment(segment.arrival_time).format("YYYY-MM-DD"),
        arrival_time: moment(segment.arrival_time).format("HH:mm"),
        checkin_status: oldLegs[direction].checkin_status,
        departure: segment.depart.AirportName,
        departure_code: segment.depart.CityCode,
        departure_date: moment(segment.departure_time).format("YYYY-MM-DD"),
        departure_time: moment(segment.departure_time).format("HH:mm"),
        flight_no: segment.flight_number,
        segment: index + 1,
        way: oldLegs[direction].way,
      }
    })
  }
  return {
    segments,
    passengers,
    layoverTimes,
    tickets,
  }
}

export default {
  formatAddress,
  getTripDirection,
  isBlackCar,
  isBlackCarAirline,
  isExpress,
  isExpressAirline,
  isExpressHome,
  isExpressHomeAirline,
  containsFlight,
}
