import "./CustomMap.scss"

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import config from "../../../utils/config"

const CustomMap = ({ pickUpAddress, dropOffAddress }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
  })

  const pickUpCoords = {
    lng: parseFloat(pickUpAddress.longitude),
    lat: parseFloat(pickUpAddress.latitude),
  }

  const dropOffCoords = {
    lng: parseFloat(dropOffAddress.longitude),
    lat: parseFloat(dropOffAddress.latitude),
  }

  const samePickUpAndDropOff = JSON.stringify(pickUpCoords) === JSON.stringify(dropOffCoords)

  const [map, setMap] = useState()

  const onLoad = React.useCallback(function callback(mapRef) {
    setMap(mapRef)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  useEffect(() => {
    if (isLoaded && map) {
      const { google } = window

      const locationsArr =
        pickUpCoords && dropOffCoords
          ? [pickUpCoords, dropOffCoords]
          : [{ lat: 46.21506817369399, lng: -94.89001725625972 }]

      const bounds = new google.maps.LatLngBounds()
      for (let i = 0; i < locationsArr.length; i += 1) {
        bounds.extend(locationsArr[i])
      }

      if (samePickUpAndDropOff) {
        map.setZoom(10)
        map.setCenter(pickUpCoords)
      } else {
        map.fitBounds(bounds)
      }
    }
  }, [isLoaded, map, pickUpCoords, dropOffCoords])

  if (!isLoaded) return <div className="custom-map-container" />

  if (loadError) return "Error"

  const markersArr =
    pickUpCoords && dropOffCoords
      ? [pickUpCoords, dropOffCoords]
      : [
          { lat: 0, lng: 0 },
          { lat: 0, lng: 0 },
        ]

  return (
    <div role="button" className="custom-map-container" onClick={e => e.stopPropagation()}>
      <GoogleMap
        className="google-map"
        mapContainerStyle={{ width: "100%", height: "100%", borderRadius: "8px" }}
        zoom={10}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
        }}
        onUnmount={onUnmount}
        onLoad={onLoad}
      >
        {!samePickUpAndDropOff &&
          markersArr.map((marker, index) => {
            return (
              <Marker
                icon={{
                  url: index === 0 ? "/media/start-map-marker.svg" : "/media/end-map-marker.svg",
                }}
                position={{ ...marker }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              />
            )
          })}
      </GoogleMap>
    </div>
  )
}

CustomMap.propTypes = {
  pickUpAddress: PropTypes.instanceOf(Object),
  dropOffAddress: PropTypes.instanceOf(Object),
}

CustomMap.defaultProps = {
  pickUpAddress: {},
  dropOffAddress: {},
}

export default CustomMap
