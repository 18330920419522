import Validator from "validatorjs"

const validateInput = (data, rules, customMessages, attributeNames) => {
  const validator = new Validator(data, rules, customMessages)
  if (attributeNames) {
    validator.setAttributeNames(attributeNames)
  }
  if (validator.fails()) {
    const { errors } = validator.errors
    const errs = Object.keys(errors).reduce((pr, cv) => {
      // eslint-disable-next-line prefer-destructuring
      pr[cv] = errors[cv][0]
      return pr
    }, {})
    return errs
  }
  return undefined
}

Validator.register(
  "name",
  value => {
    // requirement parameter defaults to null
    return value.match(/^([a-zA-Z]+)*$/u)
  },
  "The :attribute must contain only letters with no special characters or spaces.",
)

Validator.register(
  "phone",
  value => {
    // requirement parameter defaults to null
    return value.match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3,4})[-. )]*(\d{3,4})[-. ]*(\d{3,4})(?:x(\d+))?$/,
    )
  },
  "Please make sure your :attribute number is correct.",
)

export const validateSignUp = params => {
  const rules = {
    first_name: "required|name",
    last_name: "required|name",
    email: "required|email",
    password: "required|min:8",
    phone_number: "required|phone",
    retypePassword: "required|same:password",
  }
  const customMessages = {
    "required.first_name": "First name is required.",
    "required.last_name": "Last name is required.",
    "required.email": "Without :attribute, we can't reach you.",
    "email.email": "This is not a valid e-mail format.",
    "required.retypePassword": "Please type your password again.",
    "same.retypePassword":
      "Your passwords do not match. Please, check your typing in both fields and submit again.",
    "min.password": "Your password does not meet requirements.",
  }
  const attributeNames = {
    first_name: "FirstName",
    last_name: "LastName",
    email: "email address",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateHashSignup = params => {
  const rules = {
    password: "required|min:8",
    retypePassword: "required|same:password",
    phone_number: "required|phone",
  }
  const customMessages = {
    "required.retypePassword": "Please type your password again.",
    "same.retypePassword":
      "Your passwords do not match. Please, check your typing in both fields and submit again.",
    "min.password": "Your password does not meet requirements.",
  }
  const attributeNames = {
    first_name: "FirstName",
    last_name: "LastName",
    email: "email address",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export default { validateSignUp, validateHashSignup }
