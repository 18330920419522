import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../constants/segments"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"
import { getAccountInfo } from "../user/userActions"

const setTimestamp = timestamp => {
  return async dispatch => {
    dispatch({ type: ACTIONS.SET_TIMESTAMP, timestamp })
  }
}

const getUpcomingTrips = offset => {
  return async dispatch => {
    try {
      const timestamp = Date.now()
      dispatch(setTimestamp(timestamp))
      dispatch(startApiCall(API.GET_UPCOMING_TRIPS))
      const trips = await landlineApi.get(
        `v2/booking/reservations/upcoming/?limit=10&offset=${offset}`,
      )
      dispatch({
        type: ACTIONS.GET_UPCOMING_TRIPS_SUCCESS,
        trips: trips.data,
        offset,
        timestamp,
      })
    } catch (error) {
      dispatch(apiError(API.GET_UPCOMING_TRIPS, error))
    }
  }
}

const getPastTrips = offset => {
  return async dispatch => {
    try {
      const timestamp = Date.now()
      dispatch(setTimestamp(timestamp))
      dispatch(startApiCall(API.GET_PAST_TRIPS))
      const trips = await landlineApi.get(`v2/booking/reservations/past/?limit=10&offset=${offset}`)
      dispatch({
        type: ACTIONS.GET_PAST_TRIPS_SUCCESS,
        trips: trips.data,
        offset,
        timestamp,
      })
    } catch (error) {
      dispatch(apiError(API.GET_PAST_TRIPS, error))
    }
  }
}

const getTripDetails = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP_DETAILS))
      const trip = await landlineApi.get(`v2/bossadmin/trips/${tripID}/`)

      dispatch({ type: ACTIONS.GET_TRIP_DETAILS_SUCCESS, tripID, tripDetails: trip.data })

      return trip
    } catch (error) {
      dispatch(apiError(API.GET_TRIP_DETAILS, error))
      return false
    }
  }
}

const startTrip = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.START_TRIP))
      const trip = await landlineApi.post(`v2/bossadmin/trips/${tripID}/start_ride/`)

      dispatch({ type: ACTIONS.START_TRIP_SUCCESS, tripID, tripDetails: trip.data })

      toast.success("Trip started successfully.")
    } catch (error) {
      dispatch(apiError(API.START_TRIP, error))
    }
  }
}

const completeTrip = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.COMPLETE_TRIP))
      const trip = await landlineApi.post(`v2/bossadmin/trips/${tripID}/end_ride/`)

      dispatch({ type: ACTIONS.START_TRIP_SUCCESS, tripID, tripDetails: trip.data })

      toast.success("Trip completed successfully.")
    } catch (error) {
      dispatch(apiError(API.COMPLETE_TRIP, error))
    }
  }
}

const resetTripState = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.RESET_TRIP_STATE_SUCCESS })
  }
}

const getHashTrip = tripHash => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          reservation: tripHash,
        },
      }
      dispatch(startApiCall(API.GET_HASH_TRIP))

      const trip = await landlineApi.get(`v2/booking/tickets/c/${tripHash}/`, config)

      await dispatch(getAccountInfo(tripHash))

      dispatch({ type: ACTIONS.GET_HASH_TRIP_SUCCESS, selectedTrip: trip.data })
    } catch (error) {
      dispatch(apiError(API.GET_HASH_TRIP, error))
    }
  }
}

const updatePickupDetails = ({
  legID,
  direction,
  data,
  tripHash,
  segment,
  updateOtherSegment = false,
  otherSegmentID,
  withoutSuccessMessage = false,
  callback = () => {},
}) => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          reservation: tripHash,
        },
      }
      dispatch(startApiCall(API.UPDATE_PICKUP_DETAILS))
      const response = await landlineApi.post(
        `v2/booking/pick_up_details/${legID}/${direction}/`,
        data,
        tripHash && config,
      )

      if (updateOtherSegment) {
        await dispatch(
          updatePickupDetails({
            legID: otherSegmentID,
            direction: direction === "pick_up_address" ? "drop_off_address" : "pick_up_address",
            data,
            tripHash,
            segment: segment === SEGMENT_DEPART ? SEGMENT_RETURN : SEGMENT_DEPART,
            withoutSuccessMessage: true,
          }),
        )
      }

      if (!withoutSuccessMessage) {
        toast.success("Home service address successfully updated")
      }

      dispatch({ type: ACTIONS.UPDATE_PICKUP_DETAILS_SUCCESS, updatedLeg: response.data, segment })

      if (callback) {
        callback()
      }
    } catch (error) {
      dispatch(apiError(API.UPDATE_PICKUP_DETAILS, error))
    }
  }
}

const updateTimeOffset = ({ tripHash, legID, offset, segment, segmentID, noMessage = false }) => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          reservation: tripHash,
        },
      }
      const data = {
        pick_up_details: {
          offset,
        },
      }
      dispatch(startApiCall(API.UPDATE_TIME_OFFSET))

      await landlineApi.patch(`v2/booking/pick_up_details/${legID}/`, data, tripHash && config)

      const updatedSegment = await landlineApi.get(
        `v2/booking/segments/${segmentID}/`,
        tripHash && config,
      )

      if (!noMessage) {
        toast.success("Time successfully updated")
      }

      dispatch({
        type: ACTIONS.UPDATE_TIME_OFFSET_SUCCESS,
        updatedSegment: updatedSegment.data,
        segment,
      })
    } catch (error) {
      dispatch(apiError(API.UPDATE_TIME_OFFSET, error))
    }
  }
}

const setEditLegID = (direction, legID) => {
  return async dispatch => {
    dispatch({ type: ACTIONS.SET_EDIT_LEG_ID_SUCCESS, direction, legID })
  }
}

const switchCheckedBaggage = (segmentID, checked, tripHash, otherSegmentCallback, callback) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.SWITCH_CHECKED_BAGGAGE))
      const config = {
        headers: {
          reservation: tripHash,
        },
      }
      const response = await landlineApi.get(
        `v2/booking/segments/${segmentID}/${checked}/`,
        tripHash && config,
      )

      if (otherSegmentCallback) {
        otherSegmentCallback()
      }

      if (callback) {
        callback()
      }

      dispatch({ type: ACTIONS.SWITCH_CHECKED_BAGGAGE_SUCCESS, updatedSegment: response.data })
    } catch (error) {
      dispatch(apiError(API.SWITCH_CHECKED_BAGGAGE, error))
    }
  }
}

const getTripByPNR = (pnr, navigate) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP_BY_PNR))
      const trip = await landlineApi.get(`v2/booking/tickets/${pnr}/`)

      dispatch({ type: ACTIONS.GET_TRIP_BY_PNR_SUCCESS, selectedTrip: trip.data })
    } catch (error) {
      navigate("/")
      dispatch(apiError(API.GET_TRIP_BY_PNR, error))
    }
  }
}

const clearSelectedTrip = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.CLEAR_SELECTED_TRIP_SUCCESS })
  }
}

const savePassengerNote = (
  legID,
  tripHash,
  note,
  callback,
  segment,
  noMessage = false,
  modalCallback,
) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.SAVE_PASSENGER_NOTE))

      const config = {
        headers: {
          reservation: tripHash,
        },
      }
      const data = {
        description: note,
      }

      const response = await landlineApi.post(
        `v2/booking/trips/${legID}/post_passenger_note/`,
        data,
        tripHash && config,
      )

      if (callback) {
        callback()
      }

      if (modalCallback) {
        modalCallback()
      }

      if (!noMessage) {
        toast.success("Note saved")
      }

      dispatch({ type: ACTIONS.SAVE_PASSENGER_NOTE_SUCCESS, updatedLeg: response.data, segment })
    } catch (error) {
      dispatch(apiError(API.SAVE_PASSENGER_NOTE, error))
    }
  }
}

const updatePassengerNote = (noteID, tripHash, note, callback, segment, legID) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.UPDATE_PASSENGER_NOTE))

      const config = {
        headers: {
          reservation: tripHash,
        },
      }

      const data = {
        description: note,
      }

      const response = await landlineApi.patch(
        `v2/booking/notes/${noteID}/`,
        data,
        tripHash && config,
      )

      if (callback) {
        callback()
      }

      toast.success("Note updated")

      dispatch({ type: ACTIONS.UPDATE_PASSENGER_NOTE_SUCCESS, note: response.data, segment, legID })
    } catch (error) {
      dispatch(apiError(API.UPDATE_PASSENGER_NOTE, error))
    }
  }
}

const deletePassengerNote = (noteID, tripHash, callback, segment, legID) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.DELETE_PASSENGER_NOTE))

      const config = {
        headers: {
          reservation: tripHash,
        },
      }

      const response = await landlineApi.delete(
        `v2/booking/notes/${noteID}/delete/`,
        tripHash && config,
      )

      if (callback) {
        callback()
      }

      toast.success("Note deleted")

      dispatch({ type: ACTIONS.DELETE_PASSENGER_NOTE_SUCCESS, note: response.data, segment, legID })
    } catch (error) {
      dispatch(apiError(API.DELETE_PASSENGER_NOTE, error))
    }
  }
}

const sendConfirmationEmail = (reservationID, tripHash) => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          reservation: tripHash,
        },
      }

      dispatch(startApiCall(API.SEND_CONFIRMATION_EMAIL))
      await landlineApi.get(`v2/booking/reservations/${reservationID}/notify/`, tripHash && config)

      dispatch({ type: ACTIONS.SEND_CONFIRMATION_EMAIL_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.SEND_CONFIRMATION_EMAIL, error))
    }
  }
}

export {
  clearSelectedTrip,
  completeTrip,
  deletePassengerNote,
  getHashTrip,
  getPastTrips,
  getTripByPNR,
  getTripDetails,
  getUpcomingTrips,
  resetTripState,
  savePassengerNote,
  sendConfirmationEmail,
  setEditLegID,
  startTrip,
  switchCheckedBaggage,
  updatePassengerNote,
  updatePickupDetails,
  updateTimeOffset,
}
